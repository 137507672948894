/* eslint-disable react/no-direct-mutation-state */

import React from 'react';
import './chat.css'
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'


class Chat extends React.Component {

  scroll
  state={
    loading:true,
    messages:[],
    pages:1,
    page:1,
    sender:this.props.location.state?this.props.location.state.sender:null,
    reciever:this.props.location.state?this.props.location.state.reciever:null,
    contactRequest:this.props.location.state?this.props.location.state.contactRequest:'',
  }

  constructor(props){
    super(props)
    console.log("isr   ",this.props.isRTL)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    
    window.scrollTo(0,0)
  }

  componentDidMount(){
    this.getMessages()
  }

  getMessages = (page,stopScroll,reload) =>{
    axios.get(`${BASE_END_POINT}messages?userId=${this.state.sender}&&friendId=${this.state.reciever}&&contactRequest=${this.state.contactRequest}&page=${page}&&limit=20`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      },
     })
    .then(response => {
      this.setState({
        loading:false,
        messages:reload?[...response.data.data.reverse()]:[...response.data.data.reverse(),...this.state.messages],
        pages:response.data.pageCount,
      })
      console.log('MESSAGES   ',response.data)   
      if(!stopScroll){
        setTimeout(()=>{
          this.scroll.scrollTop = this.scroll.scrollHeight  - this.scroll.clientHeight
        },1000)
      }
    })
    .catch(error => {
      this.setState({loading:false})
      console.log("error of messages   ",error.response);   
    })
  }
  

  sender  = (val) =>(
    <div className="sender">
      <p className="senderName">{val.sender?val.sender.fullname:val.user.fullname}</p>
      <div className="message">
        {val.dataType === 'IMAGE'&&
            <img alt=""  style={{boxShadow:' 0px 0px 2px 0px #999', borderRadius:10,float:'left'}} src={val.content} width={250} height={200} />
        }{val.dataType === 'TEXT'&&
            <p>{val.content}</p>
        }
        {val.dataType === 'LINK'&&
            <p><a href={val.content}>{val.content}</a></p>
        }
        {val.dataType === 'VIDEO'&&
            <video width="250" height="200" controls>
            <source src={val.content} type="video/mp4"/>
            <source src={val.content} type="video/ogg"/>
            Your browser does not support the video tag.
            </video>
        }
      </div>
      <p className="msgTime">{""+new Date(val.createdAt).toLocaleTimeString()}</p>
    </div>
  )

  reciever = (val)=>(
    <div className="reciever">
        <p className="senderName">{val.sender?val.sender.fullname:val.user.fullname}</p>
        <div className="message">
        {val.dataType === 'IMAGE'&&
            <img alt=""  style={{boxShadow:' 0px 0px 2px 0px #999', borderRadius:10,float:'left'}} src={val.content} width={250} height={200} />
        }{val.dataType === 'TEXT'&&
            <p>{val.content}</p>
        }
        {val.dataType === 'LINK'&&
            <p><a href={val.content}>{val.content}</a></p>
        }
        {val.dataType === 'VIDEO'&&
            <video width="250" height="200" controls>
            <source src={val.content} type="video/mp4"/>
            <source src={val.content} type="video/ogg"/>
            Your browser does not support the video tag.
            </video>
        }
        </div>
        <p className="msgTime">{""+new Date(val.createdAt).toLocaleTimeString()}</p>   
    </div>
  )


  render(){
  
    return (
      <div className="chat">
        <MDBContainer> 
          <MDBRow>
              <MDBCol xl="12" lg="12" md="12" sm="12">
               <div className="inbox">
                  <div className="topInbox">
                    <span className="online" style={{position: 'absolute',marginTop: '40px',marginLeft: '-13px'}}></span>
                    <p style={{color: '#4d4d4d',marginLeft:'1rem'}}> طلب تواصل رقم {this.state.contactRequest}
                    </p>
                    
                  </div>
                  <div ref={ref =>{this.scroll=ref}}  
                    onScroll={(e)=>{
                      if(e.target.scrollTop===0){
                        if(this.state.page<this.state.pages){
                          this.setState({page:this.state.page++})
                          this.getMessages(this.state.page,true)
                        } 
                      }
                    }} className="bodyInbox">
                    {this.state.messages.map(val=>{
                      if(val.user.id===this.state.reciever){
                        return this.reciever(val) 
                      }else{
                        return this.sender(val)
                      }
                    })
                    }

                  </div>
               </div>
              </MDBCol>
          </MDBRow>
        </MDBContainer> 
      </div> 
    )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Chat)
);
