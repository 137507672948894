import React from 'react';
import './terms.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message ,Spin} from "antd";
class Terms extends React.Component {
    state = {
        privacy_ar:'',
        privacy_en:'',
        terms_en:'',
        terms_ar:'',
        Terms:[],
        loading:true,
        
    };

    constructor(props){
        super(props)
        this.getTerms()
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        this.getTerms()

        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getTerms = () =>{
        this.setState({loading:true})
        axios.get(`${BASE_END_POINT}terms`,{
          headers:{
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
          }
        })
        .then(response=>{
          console.log("Terms   ",response.data)
          this.setState({
            loading:false,
            Terms:response.data.data,
            privacy_ar:response.data.data.privacy_ar,
            privacy_en:response.data.data.privacy_en,
            terms_en:response.data.data.terms_en,
            terms_ar:response.data.data.terms_ar,
          })
        })
        .catch(error=>{
          console.log("error   ",error.response)
          console.log("error2   ",error)
          this.setState({loading:false,})
        })
      }
    update = () => {
        const {privacy_ar,privacy_en,terms_ar,terms_en} = this.state
            let l = message.loading('Wait..', 2.5)
            const data={
                'privacy_ar':privacy_ar,
                "privacy_en":privacy_en,
                "terms_en":terms_en,
                "terms_ar":terms_ar,
            }
            console.log(data)
            axios.put(`${BASE_END_POINT}terms/${this.state.Terms.id}`,JSON.stringify(data),{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("Terms UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        
    }

   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.update()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
   
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('TERMS')) return <Redirect to='/Home' />

  return (
    <div className="App">
      <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#1D9598'}}>
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#1D9598'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>security</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{allStrings.Terms}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.privacy_en}</label>
                                        <br></br>
                                        <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  name="privacy_en"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate  onChange={this.changeHandler} defaultValue={this.state.Terms.privacy_en?this.state.Terms.privacy_en:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form90" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.privacy_ar}</label>
                                        <br></br>
                                        <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9]+$/g,'') } name="privacy_ar"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form90" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.Terms.privacy_ar?this.state.Terms.privacy_ar:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form20" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.terms_en}</label>
                                        <br></br>
                                        <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  name="terms_en"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form20" className="form-control" required validate  onChange={this.changeHandler} defaultValue={this.state.Terms.terms_en?this.state.Terms.terms_en:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form0" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.terms_ar}</label>
                                        <br></br>
                                        <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="terms_ar"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form0" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.Terms.terms_ar?this.state.Terms.terms_ar:""} />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn
                                        type="submit" style={{background:'#1D9598',color:'#fff'}}
                                        rounded
                                        className="btn-block z-depth-1a"
                                        >
                                        <MDBIcon icon="edit" className="mr-2" />
                                            {allStrings.edit}          
                                        </MDBBtn>
                                       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      </Spin>
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(Terms)
  );