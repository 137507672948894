import React from 'react';
import './add paymentPlan.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message } from "antd";


class AddPaymentPlan extends React.Component {
    state = {
        name_en:'',
        name_ar:'',
        paymentsCount: 1,
        taxRatio:15,
        payments:[{"achievementRatio":0,"costRatio":1}],
        
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    add = () => {
        const {name_en,name_ar,payments,paymentsCount,taxRatio} = this.state
            if(hasValue(name_en)&&hasValue(name_ar)){
                let l = message.loading('Wait..', 2.5)
                let data = {
                    "name_en":name_en,
                    "name_ar":name_ar,
                    "payments":payments,
                    "paymentsCount":paymentsCount,
                    "taxRatio":taxRatio
                }
                axios.post(`${BASE_END_POINT}paymentPlans`,data,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${this.props.currentUser.token}`,
                    'Accept-Language':this.props.isRTL?'ar':'en'
                    },
                })
                .then(response=>{
                    console.log("paymentPlan UPDATED  ",response.data)
                    l.then(() => message.success(allStrings.done, 2.5) )
                        this.props.history.goBack() 
                    })
                .catch(error=>{
                    console.log(error.response)
                    l.then(() => message.error(error.response.data.errors[0].msg, 2.5))
                })
        }
    }

   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.add()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    thePayments(){
        return this.state.payments.map((v, i) =>
            <div>
                <MDBRow>
                    <MDBCol md="12">
                        <label className="labelBox" style={{textAlign:this.props.isRTL?'right':'left'}}>
                            <span>{allStrings.thePayment + " " + (parseInt(i)+1)}</span>
                        </label>
                    </MDBCol>
                </MDBRow>
                <MDBRow> 
                    <MDBCol md="2">
                        {i>0&& 
                        <div className="minusInput" onClick={()=>this.removePaymentInput(i)}>
                        <MDBIcon icon="trash" className="mr-2" />           
                        </div>
                        }
                        {i===this.state.payments.length-1&& 
                        <div className="addInputInner"  onClick={()=>this.setState(prevState => ({ payments: [...prevState.payments, {costRatio:1,achievementRatio: 0}]})) }>
                        <MDBIcon icon="plus" className="mr-2" />           
                        </div>
                        }
                    </MDBCol>
                    <MDBCol md="5">
                        <div className="md-form">
                            <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.costRatio}</label>
                            <input value={v.costRatio} disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                            type="number" id="form2" min={1} name="costRatio" className="form-control" 
                            onChange={(event)=>{
                                let payments = [...this.state.payments];
                                payments[i].costRatio = event.target.value;
                                console.log("payments",payments)
                                this.setState({ payments });
                            }} required validate/>
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                    </MDBCol>
                    <MDBCol md="5">
                        <div className="md-form">
                            <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.achievementRatio}</label>
                            <input value={v.achievementRatio} disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                            type="number" id="form2" min={0} name="achievementRatio" className="form-control" 
                            onChange={(event)=>{
                                let payments = [...this.state.payments];
                                payments[i].achievementRatio = event.target.value;
                                console.log("payments",payments)
                                this.setState({ payments });
                            }} required validate/>
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                    </MDBCol>
                </MDBRow>   
            </div>

        )
    }
      
    removePaymentInput = (i) =>{
        let payments = [...this.state.payments];
        payments.splice(i,1);
        this.setState({ payments });
    } 
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('PAYMENT-PLANS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#1D9598'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>credit_card</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{allStrings.add}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form569" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.paymentsCount}</label>
                                            <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={1} value={this.state.paymentsCount}  id="form569" name="paymentsCount" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form90" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.taxRatio}</label>
                                            <input name="taxRatio" style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} onChange={this.changeHandler} type="number" min={0} value={this.state.taxRatio} id="form90" className="form-control" required validate   />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    {this.thePayments()}
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn
                                        style={{background:'#1D9598',color:'#fff'}}
                                        rounded
                                        color="#1D9598"
                                        type="submit"
                                        className="btn-block z-depth-1a"
                                        >
                                        <MDBIcon icon="plus" className="mr-2" />
                                            {allStrings.add}          
                                        </MDBBtn>
                                       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(AddPaymentPlan)
  );