import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './views.css'
import {message,TreeSelect,DatePicker,ConfigProvider} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import { withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';

const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
const { RangePicker } = DatePicker;

 
let headers = [
  { label: "الاسم بالكامل", key: "fullname" },
  { label: "رقم الهاتف", key: "phone" },
  { label: "الايميل", key: "email" },
  { label: "رقم الاعلان", key: "adveristmentNumber" },
  { label: "عنوان الاعلان", key: "adveristmentTitle" },
  { label: "المدينه", key: "city" },
  { label: "التصنيف الرئيسى", key: "category" },
  { label: "التصنيف الفرعى", key: "subCategory" },
  { label: "التاريخ", key: "date" },
];
let csvData = [
  
];
class Views extends React.Component {
  
  state={
    views:[],
    views2:[],
    loading:true,
    refresh:false,
    count:0,
    page:1,
    pages:1,
    search:null,
    countries:[],
    cities:[],
    areas:[],
    categories:[],
    subCategories:[],
    area:null,
    city:null,
    country:null,
    category:null,
    subCategory:null,
    showFilter:false,
    startDate:null,
    endDate:null,
    csvData:[],
    userType:'ALL',
    isReady:false,
    buildCsv:false,
    adveristment:this.props.location.state?this.props.location.state.adveristment?this.props.location.state.adveristment:null:null,
  }

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    let pageNum = this.state.page
    if(sessionStorage.getItem('pageName') === "VIEWS"){
      pageNum = sessionStorage.getItem('pageNum');
      this.setState({page:pageNum})
    }
    this.getViews(pageNum,false)
    this.getCategory()
    this.getCountries()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getCountries = () => {
    axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({countries:response.data.data})
    })
    .catch(error=>{
      console.log("ALL countries ERROR")
      console.log(error.response)
    })
  }
  getAreas = (city) => {
    axios.get(`${BASE_END_POINT}areas/${city}/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({areas:response.data.data})
    })
    .catch(error=>{
      console.log("ALL areas ERROR")
      console.log(error.response)
    })
  }
  getCities = (country) => {
      axios.get(`${BASE_END_POINT}countries/${country}/cities/withoutPagenation/get?country=${country}`)
      .then(response=>{
        console.log(response.data)
        this.setState({cities:response.data.data})
      })
      .catch(error=>{
        console.log("ALL cities ERROR")
        console.log(error.response)
      })
  }
  getCategory = () => {
    axios.get(`${BASE_END_POINT}categories`)
    .then(response=>{
      console.log(response.data)
      this.setState({categories:response.data.data})
    })
    .catch(error=>{
      console.log("ALL categories ERROR")
      console.log(error.response)
    })
  }
  getSubCategory = (category) => {
    axios.get(`${BASE_END_POINT}categories/${category}/sub-categories`)
    .then(response=>{
      console.log(response.data)
      this.setState({subCategories:response.data.data})
    })
    .catch(error=>{
      console.log("ALL subCategories ERROR")
      console.log(error.response)
    })
  }

  delete = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}views/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("views DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getViews(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
  }
  getViews = (page,refresh) =>{
    this.setState({loading:true})
    let {adveristment,search,city,area,country,userType,category,subCategory,startDate,endDate} = this.state
    let url = `${BASE_END_POINT}views?page=${page}`
    if(refresh !== true ){
      let keysToFilter = {adveristment,search,city,area,country,userType,category,subCategory,startDate,endDate}
      Object.keys(keysToFilter).forEach(key => {
        if (keysToFilter[key] !== null) {
          url += `&${key}=${keysToFilter[key]}`;
        }
      });
    }else{
      this.setState({
        city:null,area:null,country:null,userType:null,search:null,
        category:null,subCategory:null,startDate:null,endDate:null
      })
    }
    axios.get(url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      },
     })
    .then(response=>{
       console.log("Views   ",response.data)
      let data = response.data.data.map((item,index)=>{
      return  [
        item.id?item.id:"",
        item.user?item.user.fullname?item.user.fullname.substr(0,20):"غير مسجل":"غير مسجل",
        item.relatedTo==="ADVERTISEMENT"?item.viewOn?item.viewOn.id:"":"",
        item.relatedTo==="ADVERTISEMENT"?item.viewOn?item.viewOn.title:"":"",
        item.city?{type:'tag',value:item.city.cityName.substr(0,20)}:{type:'tag',value:'غير معروف'},
        item.category?{type:'tag',value:item.category.categoryName.substr(0,20)}:{type:'tag',value:'غير معروف'},
        item.subCategory?{type:'tag',value:item.subCategory.categoryName.substr(0,20)}:{type:'tag',value:'غير معروف'},
        item.createdAt?this.props.isRTL?moment(item.createdAt).locale('ar').format('ll'):moment(item.createdAt).locale('en').format('ll'):"",
        "action"
      ]
      })  
      this.setState({
        loading:false,
        refresh:false,
        views:response.data.data,
        views2:data,
        count:response.data.totalCount,
        page:response.data.page,
        pages:response.data.pageCount,
      })
      sessionStorage.setItem('pageNum', page);
      sessionStorage.setItem('pageName', "VIEWS");
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getViewsWithoutPagenation = (refresh) =>{
    let {adveristment,search,city,area,country,category,userType,subCategory,startDate,endDate} = this.state
    let url = `${BASE_END_POINT}views/withoutPagenation/get?all=true`
    if(refresh !== true ){
      let keysToFilter = {adveristment,search,city,area,country,userType,category,subCategory,startDate,endDate}
      Object.keys(keysToFilter).forEach(key => {
        if (keysToFilter[key] !== null) {
          url += `&${key}=${keysToFilter[key]}`;
        }
      });
    }else{
      this.setState({
        city:null,area:null,country:null,userType:null,search:null,
        category:null,subCategory:null,startDate:null,endDate:null
      })
    }
    
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      }
    })
    .then(response=>{
      
        csvData= response.data.data.map(item => {
          return {
            ...{fullname:item.user?item.user.fullname?item.user.fullname:"غير مسجل":"غير مسجل"},
            ...{phone:item.user?item.user.phone?item.user.phone:"غير مسجل":"غير مسجل"},
            ...{email:item.user?item.user.email?item.user.email:"غير مسجل":"غير مسجل"},
            ...{adveristmentNumber:item.viewOn?item.viewOn.id?item.viewOn.id:"":""},
            ...{adveristmentTitle:item.viewOn?item.viewOn.title?item.viewOn.title:"":""},
            ...{city:item.city?item.city.cityName.substr(0,20):'غير معروف'},
            ...{category:item.category?item.category.categoryName.substr(0,20):'غير معروف'},
            ...{subCategory:item.subCategory?item.subCategory.categoryName.substr(0,20):'غير معروف'},
            ...{date:item.createdAt?this.props.isRTL?moment(item.createdAt).locale('ar').format('ll'):moment(item.createdAt).locale('en').format('ll'):""}            
          };
        });
        this.setState({csvData:csvData,isReady:true})      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }

 


  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getViews(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getViews(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getViews(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#659FF1'} !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getViews(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getViews(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )
  onChange = (date, dateString) =>{
    if(this.props.isRTL){
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD"),
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
    }else{
      this.setState({to:moment(dateString).locale('en').format("YYYY/MM/DD") })
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD") ,
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
      console.log(moment(dateString[0]).locale('en').format("YYYY-MM-DD") )

    }
    console.log( dateString);
  }
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('VIEWS')) return <Redirect to='/Home' />

   console.log(this.state.search)
  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          <Table
            settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{zIndex: '1',position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.getViews(1,true);this.setState({buildCsv:false})}}
                class="material-icons">
                  replay
                </span>
                {!this.state.buildCsv && 
                  <img crossorigin="anonymous"alt="" onClick={() => {this.setState({buildCsv:true});this.getViewsWithoutPagenation()}}
                  style={{padding: '0px',position: 'relative',top: '-6px',marginLeft:'5px',cursor: 'pointer'}} 
                  src={require('../../assets/images/buildCsv.png')} width="26"></img>
                }{!this.state.isReady && this.state.buildCsv&&
                  <img crossorigin="anonymous" alt="" 
                  style={{padding: '0px',position: 'relative',top: '-5px',marginLeft:'-15px'}} 
                  src={require('../../assets/images/loading.gif')} width="70"></img>
                }{this.state.isReady && this.state.buildCsv&&
                <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"views.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
                }
              </div>
              
            }
            filter ={
              <div>
                <div className={this.state.showFilter?'showClass':'hideClass'}>
                  <MDBRow>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.area}</label>
                        <TreeSelect virtual={false}
                            showSearch={false}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.area}
                            value={this.state.area}
                            showCheckedStrategy= {SHOW_PARENT}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({area:value});
                            
                            }} 
                        
                        >
                            {this.state.areas.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.areaName_ar:val.areaName_en} key={val.id} />
                            ))}
                        </TreeSelect>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.city}</label>
                        <TreeSelect virtual={false}
                            showSearch={true}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.city}
                            value={this.state.city}
                            showCheckedStrategy= {SHOW_PARENT}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({city:value});
                            this.getAreas(value)
                            }} 
                        
                        >
                            {this.state.cities.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.cityName_ar:val.cityName_en} key={val.id} />
                            ))}
                        </TreeSelect>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                        <TreeSelect virtual={false}
                            showSearch={false}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.country}
                            value={this.state.country}
                            showCheckedStrategy= {SHOW_PARENT}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({country:value});
                            this.getCities(value)
                            }} 
                        
                        >
                            {this.state.countries.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.countryName_ar:val.countryName_en} key={val.id} />
                            ))}
                        </TreeSelect>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.subCategory}</label>
                        <TreeSelect virtual={false}
                            showSearch={true}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.subCategory}
                            value={this.state.subCategory}
                            showCheckedStrategy= {SHOW_PARENT}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({subCategory:value});
                            }} 
                        
                        >
                            {this.state.subCategories.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                            ))}
                        </TreeSelect>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                        <TreeSelect virtual={false}
                            showSearch={false}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.category}
                            value={this.state.category}
                            showCheckedStrategy= {SHOW_PARENT}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({category:value});
                            this.getSubCategory(value)
                            }} 
                        
                        >
                            {this.state.categories.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                            ))}
                        </TreeSelect>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                      <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                      <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                        <RangePicker format={"YYYY-MM-DD"} placeholder={[allStrings.startDate,allStrings.endDate]} onChange={this.onChange} />
                      </ConfigProvider>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                    <Button style={{background: '#1D9598',color: '#fff',width: '100%',margin: '32px auto'}} 
                    onClick={()=>{this.getViews(1);this.setState({buildCsv:false})}}><SearchOutlined /> {allStrings.Search}</Button>

                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                      <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.userType}</label>
                      <TreeSelect virtual={false}
                          showSearch={false} 
                          style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                          treeCheckable = {false}         
                          treeNodeFilterProp="title"                      
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          placeholder={allStrings.userType}
                          onChange={(value) => {
                          console.log(value)
                          this.setState({userType:value});
                          }} 
                      
                      >
                        
                          <TreeNode value="USER" title={allStrings.client} key="USER" />
                          <TreeNode value="OFFICE" title={allStrings.office} key="OFFICE" />
                          
                      </TreeSelect>
                    </MDBCol>
                    
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                      <div class="md-form">
                      <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.Search}</label>
                      <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',fontWeight:'300'}} type="text" id="form605"  class="form-control"
                        placeholder={allStrings.SearchByName}
                        onChange={(e)=>{
                          this.setState({search:e.target.value})
                        }}  
                      />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  
                </div>
              </div>
            }
            enableEdit={false}
            title={allStrings.views}
            icon='pageview'
            data={this.state.views}
            data2={this.state.views2}
            tableColumns={["#",allStrings.client,allStrings.theAdveristment,allStrings.title,allStrings.city,allStrings.category,allStrings.subCategory,allStrings.createdAt,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.delete(id)}}
            path='/viewsInfo'
            dataCount={this.state.count}
            actionIndex={7}
            />
            {this.paginationButtons()}
            
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Views)
);

