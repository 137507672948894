import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css';

import { BrowserRouter , Switch ,Route} from 'react-router-dom';
import { Provider } from 'react-redux'
import {store,persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import Login from './screens/login/login'
import ForgetPassword from './screens/forgetPassword/forgetPassword'
import Splash from './screens/splash/splash'
import Categories from './screens/categories/categories'
import CategoryInfo from './screens/category info/category info'
import SubCategoryInfo from './screens/subCategory info/subCategory info'
import AddCategory from './screens/add category/add category'
import AddSubCategory from './screens/add subCategory/add subCategory'
import UserInfo from './screens/user info/user info'
import Users from './screens/user/user';
import Admins from './screens/admin/admin'
import Search from './screens/search/search'

import AddUser from './screens/add user/add user'
import cityInfo from './screens/city info/city info';
import areaInfo from './screens/area info/area info';
import setting from './screens/setting/setting';
import sendNotifs from './screens/send notifs/send notifs';
import Home from './screens/home/Home';
import About from './screens/about/about';
import Countries from './screens/countries/countries';
import CountryInfo from './screens/country info/country info'
import AddCountry from './screens/add country/add country'

import anoncement from './screens/anoncement/anoncement';
import anoncementInfo from './screens/anoncement info/anoncement info';
import addAnoncement from './screens/add anoncement/add anoncement';
import Questions from './screens/question/question';
import QuestionsInfo from './screens/question info/question info';
import addQuestions from './screens/add question/add question';
import Features from './screens/features/features';
import FeatureInfo from './screens/features info/features info';
import AddFeatures from './screens/add features/add features';
import Terms from './screens/terms/terms';
import RegionInfo from './screens/region info/region info';
import PackageInfo from './screens/package info/package info';
import Packages from './screens/package/package';
import AddPackage from './screens/add package/add package';
import Offices from './screens/office/office';
import Ads from './screens/ads/ads';
import AdsInfo from './screens/ads info/ads info';
import Request from './screens/request/request';
import RequestInfo from './screens/request info/request info';
import Permissions from './screens/permission/permission';
import PermissionInfo from './screens/permission info/permission info';
import AddPermission from './screens/add permission/add permission';
import Supervisor from './screens/supervisor/supervisor';
import Transactions from './screens/transaction/transaction';
import TransactionInfo from './screens/transaction info/transaction info'
import News from './screens/news/news';
import NewsInfo from './screens/news info/news info';
import AddNews from './screens/add news/add news'
import ContactRequests from './screens/contactRequest/contactRequest';
import ContactRequestsInfo from './screens/contactRequest info/contactRequest info';
import Views from './screens/views/views';
import MarketViews from './screens/market views/market views';
import MarketTerms  from './screens/market terms/market terms';
import ViewsInfo from './screens/view info/view info';
import SearchHistory from './screens/search history/search history';
import SearchInfo from './screens/search info/search info';
import PaymentTerms from './screens/payment terms/payment terms';
import TermsInfo from './screens/terms info/terms info';
import Business from './screens/business/business';
import BusinessInfo from './screens/business info/business info';
import Bank from './screens/bank/bank';
import BankInfo from './screens/bank info/bank info';
import AddBank from './screens/add bank/add bank'
import Services from './screens/service/service'
import ServiceInfo from './screens/service info/service info';
import AddService from './screens/add service/add service'
import SendMarketingNotif from './screens/send marketing  notifs/send marketing notifs'
import marketingInfo from './screens/marketing info/marketing info';
import marketAds from './screens/market ads/market ads'
import Chat from './screens/chat/chat'
import MarketingNotifInfo from './screens/notif info/notif info'
import RedfAds from './screens/redf ads/ads'
import Clients from './screens/clients/clients'
import ClientInfo from './screens/client info/client info';
import Interest from './screens/interest/interest';
import InterestInfo from './screens/interest info/interest info';
import Company from './screens/company/company';
import CompanyInfo from './screens/company info/company info';
import AddCompany from './screens/add company/add company'
import PaymentPlan from './screens/paymentPlan/paymentPlan';
import PaymentPlanInfo from './screens/paymentPlan info/paymentPlan info';
import AddPaymentPlan from './screens/add paymentPlan/add paymentPlan'
import AddDeal from './screens/add deal/add deal';
import Deal from './screens/deal/deal';
import DealInfo from './screens/deal info/deal info';
import InstallmentInfo from './screens/installment info/installment info';
import OfficeRequest from './screens/officeRequest/officeRequest';
ReactDOM.render(
  
<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter >
      <Switch>
        <Route path={"/Login"} component={Login}/> 
        <Route exact path={"/"} component={Splash}/> 
        <Route path={"/forgetPassword"} component={ForgetPassword}/>
        <App path={'/OfficeRequests'} component={OfficeRequest}/> 
        <App path={'/AddDeal'} component={AddDeal}/>
        <App path={'/Deals'} component={Deal}/> 
        <App path={'/InstallmentInfo'} component={InstallmentInfo}/> 
        <App path={'/DealInfo'} component={DealInfo}/> 

        <App path={'/PaymentPlans'} component={PaymentPlan}/> 
        <App path={'/AddPaymentPlan'} component={AddPaymentPlan}/> 
        <App path={'/PaymentPlanInfo'} component={PaymentPlanInfo}/> 
        <App path={'/Companies'} component={Company}/> 
        <App path={'/AddCompany'} component={AddCompany}/> 
        <App path={'/CompanyInfo'} component={CompanyInfo}/> 
        <App path={'/RedfAds'} component={RedfAds}/> 
        <App path={'/marketingInfo'} component={marketingInfo}/> 
        <App path={'/Chat'} component={Chat}/> 
        <App path={'/marketAds'} component={marketAds}/> 
        <App path={'/MarketTerms'} component={MarketTerms}/> 
        <App path={'/MarketViews'} component={MarketViews}/> 
        <App path={'/SendMarketingNotif'} component={SendMarketingNotif}/> 
        <App path={'/Clients'} component={Clients}/> 
        <App path={'/ClientInfo'} component={ClientInfo}/>
        <App path={'/Interests'} component={Interest}/> 
        <App path={'/InterestInfo'} component={InterestInfo}/>
        <App path={'/Banks'} component={Bank}/> 
        <App path={'/AddBank'} component={AddBank}/> 
        <App path={'/bankInfo'} component={BankInfo}/> 
        <App path={'/Services'} component={Services}/> 
        <App path={'/AddService'} component={AddService}/> 
        <App path={'/serviceInfo'} component={ServiceInfo}/> 
        <App path={'/Business'} component={Business}/> 
        <App path={'/BusinessInfo'} component={BusinessInfo}/> 
        <App path={'/ContactRequests'} component={ContactRequests}/> 
        <App path={'/ContactRequestsInfo'} component={ContactRequestsInfo}/> 
        <App path={'/SearchHistory'} component={SearchHistory}/> 
        <App path={'/SearchInfo'} component={SearchInfo}/> 
        <App path={'/PaymentTerms'} component={PaymentTerms}/> 
        <App path={'/paymentTermsInfo'} component={TermsInfo}/> 
        <App path={'/Views'} component={Views}/> 
        <App path={'/viewsInfo'} component={ViewsInfo}/> 
        <App path={'/AddNews'} component={AddNews}/> 
        <App path={'/NewsInfo'} component={NewsInfo}/> 
        <App path={'/News'} component={News}/> 
        <App path={'/Transactions'} component={Transactions}/> 
        <App path={'/TransactionInfo'} component={TransactionInfo}/> 
        <App path={'/PermissionInfo'} component={PermissionInfo}/> 
        <App path={'/Permissions'} component={Permissions}/> 
        <App path={'/AddPermission'} component={AddPermission}/> 
        <App path={'/Supervisors'} component={Supervisor}/> 
        <App path={'/Offices'} component={Offices}/> 
        <App path={'/Ads'} component={Ads}/>
        <App path={'/Requests'} component={Request}/>
        <App path={'/RequestInfo'} component={RequestInfo}/>
        <App path={'/AdsInfo'} component={AdsInfo}/>
        <App path={'/Packages'} component={Packages}/>
        <App path={'/PackageInfo'} component={PackageInfo}/>
        <App path={'/AddPackage'} component={AddPackage}/>
        <App path={'/Search'} component={Search}/>
        <App path={'/Features'} component={Features}/>
        <App path={'/FeatureInfo'} component={FeatureInfo}/>
        <App path={'/regionInfo'} component={RegionInfo}/>
        <App path={'/AddFeature'} component={AddFeatures}/>
        <App path={'/Terms'} component={Terms}/>
        <App path={'/Questions'} component={Questions}/>
        <App path={'/questionInfo'} component={QuestionsInfo}/>
        <App path={'/AddQuestion'} component={addQuestions}/>
        <App path={'/Anoncements'} component={anoncement}/>
        <App path={'/anoncementInfo'} component={anoncementInfo}/>
        <App path={'/addAnoncement'} component={addAnoncement}/>
        <App path={'/AddCountry'} component={AddCountry}/>
        <App path={'/Countries'} component={Countries}/>
        <App path={'/CountryInfo'} component={CountryInfo}/>
        <App path={'/categories'} component={Categories}/>
        <App path={'/about'} component={About}/>
        <App path={'/Home'} component={Home}/>
        <App path={'/CategoryInfo'} component={CategoryInfo}/>
        <App path={'/subCategoryInfo'} component={SubCategoryInfo}/>
        <App path={'/addCategory'} component={AddCategory}/>
        <App path={'/addSubCategory'} component={AddSubCategory}/>
        <App path={'/users'} component={Users}/>
        <App path={'/Admins'} component={Admins}/>
        <App path={'/userInfo'} component={UserInfo}/>
        <App path={'/addUser'} component={AddUser}/>
        <App path={'/Setting'} component={setting}/>
        <App path={'/SendNotifs'} component={sendNotifs}/>
        <App path={'/MarketingNotifInfo'} component={MarketingNotifInfo}/>
        <App path={'/cityInfo'} component={cityInfo}/>
        <App path={'/areaInfo'} component={areaInfo}/>
        
      </Switch>
    </BrowserRouter>
  </PersistGate>
</Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
