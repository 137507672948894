/* eslint-disable eqeqeq */
import React from 'react';
import './send notifs.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import Button from '@material-ui/core/Button';
import Skeleton from 'react-loading-skeleton';
import Pagination from '@material-ui/lab/Pagination';
import { message,Empty ,TreeSelect} from "antd";
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class SendNotif extends React.Component {
    state = {
        description:'',
        title:'',
        subjectId:null,
        notifType:'APP',
        notifs:[],
        page:1,
        pages:1,
        userType:'ALL',
        users:[],
        usersId:null,
        usersIds:[],
        country:null,
        countries:[],
        city:null,
        cities:[],
        area:null,
        areas:[]
    };

    constructor(props){
        super(props)
        this.getNotifs()
        this.getCountries()
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
     }
    getCountries = () => {
        axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`)
        .then(response=>{
          console.log(response.data)
          this.setState({countries:response.data.data})
        })
        .catch(error=>{
          console.log("ALL countries ERROR")
          console.log(error.response)
        })
    }
    getAreas = (city) => {
        axios.get(`${BASE_END_POINT}areas/${city}/withoutPagenation/get`)
        .then(response=>{
          console.log(response.data)
          this.setState({areas:response.data.data})
        })
        .catch(error=>{
          console.log("ALL areas ERROR")
          console.log(error.response)
        })
    }
    getCities = (country) => {
        axios.get(`${BASE_END_POINT}countries/${country}/cities/withoutPagenation/get?country=${country}`)
          .then(response=>{
            console.log(response.data)
            this.setState({cities:response.data.data})
          })
          .catch(error=>{
            console.log("ALL cities ERROR")
            console.log(error.response)
          })
    }
    getUsersWithoutPagenation = (type) =>{
    let url = `${BASE_END_POINT}quickSearch/get?page=1`
    
    let {city,area,country} = this.state
    if(country !== null){
        let str = '&country='+ country
        url += str
    }
    if(city !== null){
        let str = '&city='+ city
        url += str
    }
    if(area !== null){
        let str = '&area='+ area
        url += str
    }
    if(type !== null){
        if(type !='ALL'){
            let str = '&type='+ type
            url += str
        }
    }
    axios.get(url,{
        headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        }
    })
    .then(response=>{
        this.setState({users:response.data.data})
    })
    .catch(error=>{
        console.log("error   ",error.response)
        console.log("error2   ",error)
    })
    }
    SendNotif = () => {
        const {description,title,userType,usersId,country,city,area,subjectId,notifType} = this.state
        if(hasValue(notifType)){
            let l = message.loading('Wait..', 2.5)
            const data={
                'description':description,
                'title':title,
                'type':notifType
            }
            if(subjectId) data.subjectId = subjectId
            console.log("usersId :",usersId)
            if(usersId !== null){
                data.users = usersId
            }else{
                if(country) data.country = country
                if(city) data.city = city
                if(area) data.area = area
                data.userType = userType
            }
            console.log(data)
            axios.post(`${BASE_END_POINT}notif/SendNotifs`,JSON.stringify(data),{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("send notif ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    getNotifs = (page,refresh) =>{
        this.setState({loading:true})
        axios.get(`${BASE_END_POINT}notif?fromAdmin=true&page=${page}`,{
          headers:{
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
          }
        })
        .then(response=>{
          console.log("Notifs   ",response.data)
          this.setState({
            loading:false,
            refresh:false,
            notifs:response.data.data,
            page:response.data.page,
            pages:response.data.pageCount,
          })
          console.log('nor',response.data.data[0])
          console.log('rev',Object.keys(response.data.data[0]).reverse())
        })
        .catch(error=>{
          console.log("error   ",error.response)
          console.log("error2   ",error)
          this.setState({loading:false,})
        })
    
    }

   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.SendNotif()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    paginationButtons = () =>(
        <CardActions style={{display: 'inline-flex'}}>
            <Button 
            onClick={()=>{
              if(this.state.page!==1){
                this.getNotifs(1)
                this.setState({page:1})
              }
            }}
            className="pagenation" >{allStrings.first}</Button>
    
            <Button 
            onClick={()=>{
             if(this.state.page>1){
                this.getNotifs(this.state.page-1)
                this.setState({page:this.state.page-1})
             }
            }}
            className="pagenation">{allStrings.prev}</Button>
            
            <Pagination
            onChange={(event,page)=>{
            console.log("page   ",page)
            if(page!==this.state.page){
              this.getNotifs(page)
              this.setState({page:page})
            }
            
            }} 
            defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#1D9598 !important`}} />
            
            <Button 
            onClick={()=>{
              if(this.state.page<this.state.pages){
                this.getNotifs(this.state.page+1)
                this.setState({page:this.state.page+1})
              }
            }}
            className="pagenation">{allStrings.next}</Button>
    
            <Button 
             onClick={()=>{
              if(this.state.page!==this.state.pages){
                this.getNotifs(this.state.pages)
                this.setState({page:this.state.pages})
              }
            }}
            className="pagenation">{allStrings.last}</Button>
    
        </CardActions>
    )
   
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('FEATURES')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#1D9598'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>circle_notifications</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{allStrings.SendNotif}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.userType}</label>
                                            <TreeSelect 
                                                virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.userType}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({userType:value,users:[]});
                                                this.getUsersWithoutPagenation(value)
                                                }} 
                                            
                                            >
                                                <TreeNode value="ALL" title={allStrings.all} key="ALL" />
                                                <TreeNode value="ADMIN" title={allStrings.admin} key="ADMIN" />
                                                <TreeNode value="SUB-ADMIN" title={allStrings.subAdmin} key="SUB-ADMIN" />
                                                <TreeNode value="USER" title={allStrings.client} key="USER" />
                                                <TreeNode value="OFFICE" title={allStrings.office} key="OFFICE" />
                                                <TreeNode value="MARKETERS" title={allStrings.marketers} key="MARKETERS" />

                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        {this.state.userType !== 'ALL'&&
                                        <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.users}</label>
                                          <TreeSelect 
                                              virtual={false}
                                              showSearch={true} 
                                              maxTagCount="6"
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {true}        
                                              showCheckedStrategy= {SHOW_PARENT}   
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.users}
                                              onChange={(value) => {
                                              console.log(value)
                                                this.setState({usersId:value});
                                                
                                              }} 
                                          
                                          >
                                              
                                              {this.state.users.map(val=>(
                                                  <TreeNode value={val.id} title={val.fullname} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                        </MDBCol>
                                        }
                                        
                                        
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="4" sm="12">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.area}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.area}
                                                value={this.state.area}
                                                showCheckedStrategy= {SHOW_PARENT}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({area:value});
                                                
                                                }} 
                                            
                                            >
                                                {this.state.areas.map(val=>(
                                                    <TreeNode value={val.id} title={this.props.isRTL?val.areaName_ar:val.areaName_en} key={val.id} />
                                                ))}
                                            </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="4" sm="12">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.city}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={true}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.city}
                                                value={this.state.city}
                                                showCheckedStrategy= {SHOW_PARENT}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({city:value});
                                                this.getAreas(value)
                                                }} 
                                            
                                            >
                                                {this.state.cities.map(val=>(
                                                    <TreeNode value={val.id} title={this.props.isRTL?val.cityName_ar:val.cityName_en} key={val.id} />
                                                ))}
                                            </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="4" sm="12">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.country}
                                                value={this.state.country}
                                                showCheckedStrategy= {SHOW_PARENT}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({country:value});
                                                this.getCities(value)
                                                }} 
                                            
                                            >
                                                {this.state.countries.map(val=>(
                                                    <TreeNode value={val.id} title={this.props.isRTL?val.countryName_ar:val.countryName_en} key={val.id} />
                                                ))}
                                            </TreeSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.notifType}</label>
                                            <TreeSelect 
                                                virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.notifType}
                                                value={this.state.notifType}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({notifType:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value="ADS" title={allStrings.adsNotif} key="ADS" />
                                                <TreeNode value="NEWS" title={allStrings.newsNotif} key="NEWS" />
                                                <TreeNode value="APP" title={allStrings.appNotif} key="APP" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        {this.state.notifType !== "APP"&&
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form645" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.subjectId}</label>
                                                <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.subjectId} type="text" id="form645" name="subjectId" className="form-control" onChange={this.changeHandler} required  validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        }
                                    </MDBRow>
                                    {this.state.notifType === "APP"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form645" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.description}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.description} type="text" id="form645" name="description" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }{this.state.notifType === "APP"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.title}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.title} type="text" id="form64" name="title" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                       <MDBBtn
                                        type="submit" style={{background:'#1D9598',color:'#fff'}}
                                        rounded
                                        className="btn-block z-depth-1a"
                                        >
                                        <MDBIcon icon="plus" className="mr-2" />
                                            {allStrings.SendNotif}          
                                        </MDBBtn>
                                       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
            
            <MDBRow>
                <MDBCol md="12">
                    <div className="infoTable">
                        <div className="topInfoTable" style={{ background: "#1D9598" }}>
                            <h4>
                            <span className="material-icons" style={{color:'white',fontSize:23}}>circle_notifications</span>{ allStrings.notifs}
                            </h4>
                        </div>
                        <div className="tableDate">
                        <div className="table-responsive">
                            <table className="table table-striped">
                            <thead>
                                {this.props.isRTL?
                                <tr style={{textAlign:'center'}}>
                                    <th>{allStrings.more}</th>
                                    <th>{allStrings.usersCount}</th>
                                    <th>{allStrings.description}</th>
                                    <th>{allStrings.title}</th>
                                    <th scope="col">#</th>
                                </tr>
                                :
                                <tr style={{textAlign:'center'}}>
                                    <th scope="col">#</th>
                                    <th>{allStrings.title}</th>
                                    <th>{allStrings.description}</th>
                                    <th>{allStrings.usersCount}</th>
                                    <th>{allStrings.more}</th>
                                </tr>
                                    
                                }
                                
                            </thead>
                            <tbody>
                            {this.state.loading?
                                <tr style={{height: '200px'}}>
                                    <th colSpan={this.state.tableType === "collected" ?"5":"4"} className="noData">
                                    <Skeleton height={400}/> 
                                    </th>
                                </tr>
                                :
                                this.state.notifs.length > 0 ?
                                this.state.notifs.map(val =>(
                                    this.props.isRTL?
                                    <tr  style={{textAlign:'center'}}>
                                        <td onClick={()=>{this.props.history.push('/notifInfo',{data:val}) }} >
                                            <span style={{margin: "4px",cursor:'pointer'}}>
                                                <Tooltip title={allStrings.info} placement="top">
                                                    <Info style={{color:'#33d633'}}/>
                                                </Tooltip>
                                            </span>
                                        </td>
                                        <td>{val.usersCount?val.usersCount:'0'}</td>
                                        <td>{val.description}</td>
                                        <td>{val.title}</td>
                                        <td >{val.id}</td>
                                    </tr>
                                    :
                                    <tr  style={{textAlign:'center'}}>
                                        
                                        <td >{val.id}</td>
                                        <td>{val.title}</td>
                                        <td>{val.description}</td>
                                        <td>{val.usersCount?val.usersCount:'0'}</td>
                                        <td onClick={()=>{this.props.history.push('/notifInfo',{data:val}) }} >
                                            <span style={{margin: "4px",cursor:'pointer'}}>
                                                <Tooltip title={allStrings.info} placement="top">
                                                    <Info style={{color:'#33d633'}}/>
                                                </Tooltip>
                                            </span>
                                        </td>
                                    </tr>
                               
                                 ))
               
                                 :
                                 <tr style={{height: '200px'}}>
                                     <th colSpan="4" className="noData">
                                     <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                     </th>
                                 </tr>
                               }
                              
                               
                                
                            </tbody>
                            </table>
                            {this.state.notifs.length > 0 &&
                                <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                    {this.paginationButtons()}
                                </div>
                            }
                        </div>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
            
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(SendNotif)
  );