import React from 'react';
import './question info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,TreeSelect } from "antd";
import { UploadOutlined } from '@ant-design/icons';
const { TreeNode } = TreeSelect;

class QuestionInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        description:this.props.location.state.data.description,
        question_en:this.props.location.state.data.question_en,
        question_ar:this.props.location.state.data.question_ar,
        answer_ar:this.props.location.state.data.answer_ar,
        answer_en:this.props.location.state.data.answer_en,
        type:this.props.location.state.data.type,
        mediaName:'',
        media: null,
        mediaType:"data"

    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    updateQuestion = () => {
        const {question_en,question_ar,answer_ar,answer_en,type,media} = this.state
        let l = message.loading('Wait..', 2.5)
      
        if(hasValue(question_en)&&hasValue(question_ar)&&hasValue(answer_ar)&&hasValue(answer_en)){

            let data = new FormData()
            data.append('question_en',question_en)
            data.append('question_ar',question_ar)
            data.append('answer_ar',answer_ar)
            data.append('answer_en',answer_en)
            data.append('type',type)
            if(media != null){
              data.append('media',media); 
            }
            axios.put(`${BASE_END_POINT}questions/${this.props.location.state.data.id}`,data,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization':`Bearer ${this.props.currentUser.token}`,
                    'Accept-Language':this.props.isRTL?'ar':'en'
                },
            })
            .then(response=>{
                console.log("Question UPDATED  ",response.data)
                l.then(() => message.success(allStrings.done, 2.5) )
                this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }

    deleteQuestion = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}questions/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("Question DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
   
    fileChangedHandler = event => {
        this.setState({
          media: event.target.files[0],
          mediaName: event.target.files[0].name,
          mediaType:"data"
        })
    }
    removeFile = () => {
        this.setState({
            media:null,
            mediaName:'',
            mediaType:"url"
        });
    }
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.updateQuestion()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('FAQ')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12" >
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#1D9598'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>help_outline</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.QuestionInfo:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishQuestion}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.question_en} type="text" id="form645" name="question_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicQuestion}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.question_ar} type="text" id="form56" name="question_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                      <MDBCol md="12">
                                        <div className="md-form">
                                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishAnswer}</label>
                                          <br></br>
                                          <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } name="answer_en" onChange={this.changeHandler} value={this.state.answer_en} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate   />
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                      
                                    </MDBRow>
                                    <MDBRow>
                                      <MDBCol md="12">
                                        <div className="md-form">
                                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicAnswer}</label>
                                          <br></br>
                                          <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="answer_ar" onChange={this.changeHandler} value={this.state.answer_ar} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate   />
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6"></MDBCol>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.type}
                                                onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({type:value});

                                                }} 
                                            
                                            >
                                                <TreeNode value="GLOBAL" title={allStrings.globalQuestion} key="GLOBAL" />
                                                <TreeNode value="MARKET" title={allStrings.marketQuestion} key="MARKET" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        
                                        
                                    </MDBRow>
                                    <MDBCol md="12">
                                        <div className="fileUpload2"> 
                                            <input  key={this.state.media} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                            <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                        </div>
                                        <div className="preview">
                                        {this.state.media&&
                                        <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                            <img alt="" src={URL.createObjectURL(this.state.media)}  className="previewImg2"/>
                                            <span className="imgName">{this.state.mediaName}</span>
                                            <MDBIcon icon="trash" className="mr-2 removeImg2"
                                            onClick={() => this.removeFile()}  ></MDBIcon>
                                        </div>
                                        }
                                        </div>
                                    </MDBCol>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                    {this.state.actionType==="EDIT"&&
                                        <MDBBtn
                                        style={{background:'#1D9598',color:'#fff'}}
                                        rounded
                                        color="#1D9598"
                                        type="submit"
                                        className="btn-block z-depth-1a"
                                        >
                                            <MDBIcon icon="edit" className="mr-2" />
                                            {allStrings.update}          
                                        </MDBBtn>
                                    }{this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.deleteQuestion()}} rounded color="danger" className="btn-block z-depth-1a">
                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                            {allStrings.remove}
                                        </MDBBtn>
                                    }
                                        
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol> 
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(QuestionInfo)
  );