import React from 'react';
import './table.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Info,Edit } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import 'antd/dist/antd.css';
import { Popconfirm,Empty,Spin,Image  } from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'

import {ChangeLanguage} from '../../redux/actions/LanguageAction'

class Table extends React.Component {
  state = {
    data2:[]
  }
  constructor(props){
    super(props)
    console.log('hhhh',this.props)
    if(this.props.isRTL){
    
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    this.setState({data2:this.props.data2})
   console.log('hhhh')
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  render(){
  let {dataCount,noDelete,enableEdit,settings,filter,title,icon,data,data2,tableColumns,loading,deleteUser,path,/*reload,csv,searchInput*/} = this.props
  let isReload = data2.length>0?this.props.data2[0][0]:""; 
  if(!dataCount) dataCount = 0
  return (
    <div>
      <div className="table">
          <Card className="tableCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
            <CardHeader></CardHeader>
            <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
              <div className="topIcon" style={{background:'#1D9598'}}>
                <span className="material-icons" style={{color:'white',fontSize:23}}>{icon}</span>
              </div>
              <div className="tableTitle" style={{display:'inline-block',marginTop:this.props.isRTL?'-8px':'-10px',
              marginLeft:this.props.isRTL?'0':'0rem',marginRight:this.props.isRTL?'0rem':'0'}}>
              <p>{title}</p>
              
              </div>
            </div>
            <div style={{textAlign:this.props.isRTL?'start':'end',padding:'8px'}}>
              {settings}
            </div>
            {dataCount>0&&
            <div>
              <p style={{top:'135px',position:'absolute',padding: '4px 80px',left:this.props.isRTL?'':'0',right:this.props.isRTL?'0':'',fontWeight: '500',fontSize: '35px',color: '#1D9598'}}>
                {dataCount}
              </p>
            </div>
            }
            <div>
              {filter}
            </div>
            
            {/*<div style={{float:this.props.isRTL?'left':'right',marginRight: '20px',marginLeft:'20px',marginBottom:'10px'}}>
              {reload}
            <div style={{textAlign: "end",margin: "0px 20px 0px 40px",display:'inline-block'}}>{csv}</div>
            {searchInput}
            </div>*/}
            <CardContent style={{minHeight:"390px",outline:'none'}}>
              {loading?
                <div className='loading'>  
                  <Spin tip={allStrings.loading} size="large" style={{color:'#1D9598'}}></Spin>
                </div>
              :
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead >
                    <tr>
                    {this.props.isRTL?tableColumns.reverse().map((item,index)=>(
                      <th scope="col" key={index}>{item}</th>
                    )):
                    tableColumns.map((item,index)=>(
                      <th scope="col" key={index}>{item}</th>
                    ))
                    }
                    </tr>
                  </thead>
                  
                  <tbody>
                   
                   {
                    data2.length >0?
                      data2.map((item,index)=>(
                        <tr key={index}>
                        {this.props.isRTL&&Number.isInteger(isReload)?item.reverse().map((val,i)=>(
                          val==='action'?
                            <td key={i}>
                              {noDelete!==true&&
                              <span style={{margin: "4px",cursor:'pointer'}}>
                                <Tooltip  title={allStrings.areYouSureDelete}  placement="top" arrow>
                                <Popconfirm okText={allStrings.yes} cancelText={allStrings.no}
                                onConfirm={()=>{
                                this.props.isRTL?deleteUser(item[item.length - 1]):deleteUser(item[0])
                                }}>
                                  <CloseIcon color="secondary" style={{fontSize: '22px'}} />
                                </Popconfirm>
                                </Tooltip>
                              </span> 
                              }
                              <span onClick={()=>{this.props.history.push(path,{data:data[index]}) }} style={{margin: "4px",cursor:'pointer'}}>
                                  <Tooltip title={allStrings.info} placement="top" arrow>
                                      <Info style={{color:'#33d633',fontSize: '20px'}}/>
                                  </Tooltip>
                              </span> 
                              {enableEdit !==false&&
                              <span onClick={()=>{this.props.history.push(path,{data:data[index],actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                  <Tooltip title={allStrings.edit} placement="top" arrow>
                                      <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                  </Tooltip>
                              </span>   
                              }  
                            </td>
                            :
                            val.type==="img"?
                            <Image
                              width="50px"
                              height="50px"
                              placeholder={true}
                              style={{borderRadius:'50%',width:"50px",height:'50px',padding:'5px'}} 
                              src={val.img !==""?val.img:require('../../assets/images/profileicon.jpg')}
                            />
                            :
                            val.type==="tag"?
                            <td><p className="tag">{val.value}</p></td>
                            :
                            <td key={i} onClick={()=>{this.props.history.push(path,{data:data[index]}) }}>{""+val}</td>
                        )):
                        
                        this.props.isRTL&&!Number.isInteger(isReload)?item.map((val,i)=>(
                        val==='action'?
                          <td key={i}>
                          {noDelete!==true&&
                          <span style={{margin: "4px",cursor:'pointer'}}>
                            <Tooltip  title={allStrings.areYouSureDelete}  placement="top" arrow>
                            <Popconfirm okText={allStrings.yes} cancelText={allStrings.no}
                            onConfirm={()=>{
                            this.props.isRTL?deleteUser(item[item.length - 1]):deleteUser(item[0])
                            }}>
                              <CloseIcon color="secondary" style={{fontSize: '22px'}} />
                            </Popconfirm>
                            </Tooltip>
                          </span> 
                          }
                          <span onClick={()=>{this.props.history.push(path,{data:data[index]}) }} style={{margin: "4px",cursor:'pointer'}}>
                              <Tooltip title={allStrings.info} placement="top" arrow>
                                  <Info style={{color:'#33d633',fontSize: '20px'}}/>
                              </Tooltip>
                          </span>
                          {enableEdit !==false&&
                              <span onClick={()=>{this.props.history.push(path,{data:data[index],actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                  <Tooltip title={allStrings.edit} placement="top" arrow>
                                      <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                  </Tooltip>
                              </span>   
                          }       
                        </td>
                        :
                        val.type==="img"?
                        <Image
                          width="50px"
                          height="50px"
                          placeholder={true}
                          style={{borderRadius:'50%',width:"50px",height:'50px',padding:'5px'}} 
                          src={val.img !==""?val.img:require('../../assets/images/profileicon.jpg')}
                        />
                        :
                        val.type==="tag"?
                        <td><p className="tag">{val.value}</p></td>
                        :
                        <td key={i} onClick={()=>{this.props.history.push(path,{data:data[index]}) }}>{""+val}</td>
                        )):
                        !Number.isInteger(isReload)?item.reverse().map((val,i)=>(
                          val==='action'?
                          <td key={i}>
                            {noDelete!==true&&
                            <span style={{margin: "4px",cursor:'pointer'}}>
                              <Tooltip  title={allStrings.areYouSureDelete}  placement="top" arrow>
                              <Popconfirm okText={allStrings.yes} cancelText={allStrings.no}
                              onConfirm={()=>{
                              this.props.isRTL?deleteUser(item[item.length - 1]):deleteUser(item[0])
                              }}>
                                <CloseIcon color="secondary" style={{fontSize: '22px'}} />
                              </Popconfirm>
                              </Tooltip>
                            </span> 
                            }
                            <span onClick={()=>{this.props.history.push(path,{data:data[index]}) }} style={{margin: "4px",cursor:'pointer'}}>
                                <Tooltip title={allStrings.info} placement="top" arrow>
                                    <Info style={{color:'#33d633',fontSize: '20px'}}/>
                                </Tooltip>
                            </span>    
                            {enableEdit !==false&&
                              <span onClick={()=>{this.props.history.push(path,{data:data[index],actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                  <Tooltip title={allStrings.edit} placement="top" arrow>
                                      <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                  </Tooltip>
                              </span>   
                            }   
                          </td>
                            :
                            val.type==="img"?
                          <Image
                            width="50px"
                            height="50px"
                            placeholder={true}
                            style={{borderRadius:'50%',width:"50px",height:'50px',padding:'5px'}} 
                            src={val.img !==""?val.img:require('../../assets/images/profileicon.jpg')}
                          />
                          :
                          val.type==="tag"?
                          <td><p className="tag">{val.value}</p></td>
                          :
                          <td key={i} onClick={()=>{this.props.history.push(path,{data:data[index]}) }}>{""+val}</td>
                        )):
                        item.map((val,i)=>(
                          val==='action'?
                          <td key={i}>
                            {noDelete!==true&&
                            <span style={{margin: "4px",cursor:'pointer'}}>
                              <Tooltip  title={allStrings.areYouSureDelete}  placement="top" arrow>
                              <Popconfirm okText={allStrings.yes} cancelText={allStrings.no}
                              onConfirm={()=>{
                              this.props.isRTL?deleteUser(item[item.length - 1]):deleteUser(item[0])
                              }}>
                                <CloseIcon color="secondary" style={{fontSize: '22px'}} />
                              </Popconfirm>
                              </Tooltip>
                            </span> 
                            }
                            <span onClick={()=>{this.props.history.push(path,{data:data[index]}) }} style={{margin: "4px",cursor:'pointer'}}>
                                <Tooltip title={allStrings.info} placement="top" arrow>
                                    <Info style={{color:'#33d633',fontSize: '20px'}}/>
                                </Tooltip>
                            </span>   
                            {enableEdit !==false&&
                              <span onClick={()=>{this.props.history.push(path,{data:data[index],actionType:'EDIT'}) }} style={{margin: "4px",cursor:'pointer'}}>
                                  <Tooltip title={allStrings.edit} placement="top" arrow>
                                      <Edit style={{color:'cornflowerblue',fontSize: '20px'}}/>
                                  </Tooltip>
                              </span>   
                            }    
                          </td>
                            :
                            val.type==="img"?
                          <Image
                            width="50px"
                            height="50px"
                            placeholder={true}
                            style={{borderRadius:'50%',width:"50px",height:'50px',padding:'5px'}} 
                            src={val.img !==""?val.img:require('../../assets/images/profileicon.jpg')}
                          />
                          :
                          val.type==="tag"?
                          <td><p className="tag">{val.value}</p></td>
                          :
                          <td key={i} onClick={()=>{this.props.history.push(path,{data:data[index]}) }}>{""+val}</td>
                        ))
                        }
                        </tr>             
                    ))
                    :
                    <tr style={{height: '200px'}}>
                        <th colSpan="10" className="noData">
                        <Empty description={allStrings.noData} style={{margin:'11rem'}}/>
                        </th>
                    </tr>
                    }
                 </tbody>
                  
                </table>
              </div>
              }
            </CardContent>
        </Card>
        </div>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Table)
);

