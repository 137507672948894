import React from "react";
import "./anoncement info.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import "react-rater/lib/react-rater.css";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { message,DatePicker,TreeSelect,Image} from "antd";
import moment from "moment";
import { UploadOutlined } from '@ant-design/icons';

const { TreeNode } = TreeSelect;
class anoncementInfo extends React.Component {
  
  state = {
    actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
    title_en:this.props.location.state?this.props.location.state.data.title_en?this.props.location.state.data.title_en:'':'',
    title_ar:this.props.location.state?this.props.location.state.data.title_ar?this.props.location.state.data.title_ar:'':'',
    description_ar:this.props.location.state?this.props.location.state.data.description_ar?this.props.location.state.data.description_ar:'':'',
    description_en:this.props.location.state?this.props.location.state.data.description_en?this.props.location.state.data.description_en:'':'',
    startDate:this.props.location.state?this.props.location.state.data.startDate?this.props.location.state.data.startDate:null:null,
    endDate:this.props.location.state?this.props.location.state.data.endDate?this.props.location.state.data.endDate:null:null,
    link:this.props.location.state?this.props.location.state.data.link?this.props.location.state.data.link:'':'',
    linkType:this.props.location.state?this.props.location.state.data.linkType?this.props.location.state.data.linkType:'OUTSIDE':'OUTSIDE',
    id:this.props.location.state.data.id,
    imgName:'',
    img: null,
    loading:true,
    refresh:false,
    page:1,
    pages:1,
  };

  constructor(props){
    super(props)

    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  
  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  update = () => {
    const {description_en,title_ar,img,description_ar,title_en,startDate,endDate,link,linkType} = this.state
   
    if(hasValue(link)&&startDate !=null &&endDate != null){
      let l = message.loading('Wait..', 1.5)
        let data = new FormData()
        data.append('description_en',description_en)
        data.append('title_ar',title_ar)
        data.append('description_ar',description_ar)
        data.append('title_en',title_en)
        data.append('startDate',startDate)
        data.append('endDate',endDate)
        data.append('link',link)
        data.append('linkType',linkType)
        if(img !== null){
            data.append(`img`,img )
        }
       
        
        console.log(Array.from(data))
        
        axios.put(`${BASE_END_POINT}anoncement/${this.props.location.state.data.id}`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/anoncements')
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }
  

  delete = () => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}anoncement/${this.props.location.state.data.id}`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.props.currentUser.token}`,
      'Accept-Language':this.props.isRTL?'ar':'en'
    },
    })
    .then(response=>{
      console.log("ROW DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.props.history.goBack()
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
  }

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.update()
  };
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  fileChangedHandler = event => {
    this.setState({
      img: event.target.files[0],
      imgName: event.target.files[0].name,
      imgType:'data'
    })
    
  }
  removeFile = () => {
    this.setState({
        img:null,
        imgName:'',
        imgType:"url"
    });
  }

  imageSection = () =>{
      
    return(
      <MDBCol  xl ="4" lg = "5"  md="12" sm="12">
          <div className="infoCard">
              <div style={{width:"100%" ,padding:'10px'}}>
                <Image
                  width="100%"
                  style={{borderRadius:'6px',width:"100%",height:'280px'}} 
                  src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.props.location.state.data.img}
                />
              </div>
              <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                    <MDBIcon icon="trash-alt" className="mr-2" />
                   {allStrings.remove}
              </MDBBtn>
          </div>
      </MDBCol>
        )
  }
  onChangeStartDate = (date, dateString)=>{ 
    console.log(date, dateString);
    this.setState({startDate:dateString})
  }
  onChangeEndDate = (date, dateString)=>{ 
    console.log(date, dateString);
    this.setState({endDate:dateString})
  }

  render() {
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('ANONCEMENTS')) return <Redirect to='/Home' />
    return (
      <div className="App">
        <MDBContainer>
          <MDBRow>
            <MDBCol xl={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
              <div className="infoCard">
                <Card className="editCard" style={{ overflow: "visible", outline: "none",marginBottom: "0px"}} >
                  <CardMedia style={{ height: "0px" }} />
                  <CardHeader></CardHeader>
                  <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                      <div className="topIcon" style={{background:'#1D9598'}}>
                          <span className="material-icons" style={{color:'white',fontSize:23}}>featured_video</span>
                      </div>
                      <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                      marginLeft:'0',marginRight:'0'}}>
                      <p>{this.state.actionType==="VIEW"?allStrings.anoncementInfo:allStrings.edit}</p>
                      </div>
                  </div>
                  <CardContent style={{ minHeight: "390px", outline: "none" }}>
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandler}
                            noValidate
                          >
                            <div className="grey-text">
                            {this.state.actionType==="VIEW"&&
                              <MDBRow>
                                <MDBCol md="12">
                                  <div className="md-form">
                                    <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                    <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                    <div class="valid-feedback">{allStrings.correct}</div>
                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                            }
                              <MDBRow>
                                <MDBCol md="6">
                                  <div className="md-form">
                                    <label htmlFor="form38" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicTitle}</label>
                                    <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.title_ar} type="text" id="form38"  name="title_ar"  className="form-control" onChange={this.changeHandler}  validate/>
                                    <div class="valid-feedback">{allStrings.correct}</div>
                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                  </div>
                                </MDBCol>
                                <MDBCol md="6">
                                <div className="md-form">
                                  <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishTitle}</label>
                                  <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.title_en} type="text" id="form2" name="title_en" className="form-control" onChange={this.changeHandler}  validate/>
                                  <div class="valid-feedback">{allStrings.correct}</div>
                                  <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                </div>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow>
                                <MDBCol md="12">
                                  <div className="md-form">
                                    <label htmlFor="form7" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicDescription}</label>
                                    <br></br>
                                    <div class="valid-feedback">{allStrings.correct}</div>
                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                    <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') }  placeholder={allStrings.arabicDescription} rows="3" style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'6px'}} defaultValue={this.state.description_ar} type="text" id="form7" className="form-control" onChange={this.changeHandler} validate name="description_ar"/>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow>
                                <MDBCol md="12">
                                  <div className="md-form">
                                    <label htmlFor="form70" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishDescription}</label>
                                    <br></br>
                                    <div class="valid-feedback">{allStrings.correct}</div>
                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                    <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } placeholder={allStrings.englishDescription} rows="3" style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'6px'}} defaultValue={this.state.description_en} type="text" id="form70" className="form-control" onChange={this.changeHandler} validate name="description_en"/>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}  >
                                  <MDBCol md="6">
                                  <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.startDate}</label>
                                  <DatePicker disabled={this.state.actionType==="VIEW"?true:false} style={{width:'100%'}} placeholder={allStrings.startDate} onChange={this.onChangeStartDate} defaultValue={moment(this.state.startDate, 'YYYY-MM-DD')} />
                                  </MDBCol>
                                  <MDBCol md="6">
                                  <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.endDate}</label>
                                  <DatePicker disabled={this.state.actionType==="VIEW"?true:false} style={{width:'100%'}} placeholder={allStrings.endDate} onChange={this.onChangeEndDate} defaultValue={moment(this.state.endDate, 'YYYY-MM-DD')} />
                                  </MDBCol>
                              </MDBRow>
                              <MDBRow style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}  >
                                  <MDBCol md="6">
                                      <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.linkType}</label>
                                      <TreeSelect virtual={false}
                                          showSearch={false} 
                                          disabled={this.state.actionType==="VIEW"?true:false}
                                          style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                          treeCheckable = {false}         
                                          treeNodeFilterProp="title"  
                                          value={this.state.linkType}                    
                                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                          placeholder={allStrings.linkType}
                                          onChange={(value) => {
                                          console.log(value)
                                          this.setState({linkType:value});
                                          }} 
                                      
                                      >
                                          <TreeNode value="OUTSIDE" title={allStrings.outside} key="OUTSIDE" />
                                          <TreeNode value="INSIDE" title={allStrings.inside} key="INSIDE" />
                                          
                                      </TreeSelect>
                                  </MDBCol>
                                  <MDBCol md="6">
                                    <div className="md-form">
                                      <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.link}</label>
                                      <input disabled={this.state.actionType==="VIEW"?true:false}  style={{direction:this.props.isRTL?'rtl':'ltr'}} type="text" id="form65" name="link" className="form-control" onChange={this.changeHandler} validate required/>
                                      <div class="valid-feedback">{allStrings.correct}</div>
                                      <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                    </div>
                                  </MDBCol>
                              </MDBRow>
                              {this.state.actionType==="EDIT"&&
                              <MDBRow>
                                <MDBCol md="12">
                                    <div className="fileUpload2"> 
                                        <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                        <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                    </div>
                                    <div className="preview">
                                    {this.state.img&&
                                    <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                        <img alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img}  className="previewImg2"/>
                                        <span className="imgName">{this.state.imgName}</span>
                                        <MDBIcon icon="trash" className="mr-2 removeImg2"
                                        onClick={() => this.removeFile()}  ></MDBIcon>
                                    </div>
                                    }
                                    </div>
                                    
                                </MDBCol>
                              </MDBRow>
                            }
                            </div>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            {this.state.actionType==="EDIT"&&
                            <div className="text-center">
                              <MDBBtn type="submit" style={{ background: '#1D9598' }} rounded className="btn-block z-depth-1a" >
                                <MDBIcon icon="edit" className="mr-2" />
                                {allStrings.update}
                              </MDBBtn>
                            </div>
                            }
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </CardContent>
                </Card>
              </div>
            </MDBCol>
            {this.state.actionType==="VIEW"&&this.imageSection()}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchaddressrops = {
    setUser,
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchaddressrops)(anoncementInfo)
  );