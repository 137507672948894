/* eslint-disable eqeqeq */
import React from 'react';
import './add features.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message } from "antd";
import { TreeSelect } from 'antd';
import { UploadOutlined } from '@ant-design/icons';


const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class AddFeatures extends React.Component {
    state = {
        name_en:'',
        name_ar:'',
        type:'Boolean',
        category:[],
        limit:1,
        categories:[],
        regaKey:"",
        img: null,
        imgType:'data',
        options: [{option_en: '',option_ar: ''}],
        imgName:'',
        redfKey:"",
        
    };

    constructor(props){
        super(props)
        this.getCategories()
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    getCategories = () => {
        axios.get(`${BASE_END_POINT}categories/subCategories/withoutPagenation/get`)
        .then(response=>{
          console.log(response.data)
          this.setState({categories:response.data.categories})
        })
        .catch(error=>{
          console.log("ALL categories ERROR")
          console.log(error.response)
        })
    }
    add = () => {
        const {name_en,name_ar,category,type,img,imgType,regaKey,redfKey} = this.state
            if(hasValue(name_en)&&hasValue(name_ar) && img !=null){
                let l = message.loading('Wait..', 2.5)
                let data = new FormData()
                data.append('name_en',name_en)
                data.append('name_ar',name_ar)
                data.append('category',JSON.stringify(category))
                data.append('type',type)
                if(regaKey !== ""){
                    data.append('regaKey',regaKey)
                } 
                if(redfKey !== ""){
                    data.append('redfKey',redfKey)
                }
                if(img != null && imgType === "data"){
                    data.append('img',img)
                }
                let options= [...new Set(this.state.options.filter(e => e.option_ar != ''))]
           
                if(options.length > 0){
                    data.append('theOptions',JSON.stringify(options))
                }
                console.log(Array.from(data))
                axios.post(`${BASE_END_POINT}features`,data,{
                headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':`Bearer ${this.props.currentUser.token}`,
                'Accept-Language':this.props.isRTL?'ar':'en'
                },
                })
                .then(response=>{
                console.log("Features UPDATED  ",response.data)
                l.then(() => message.success(allStrings.done, 2.5) )
                    this.props.history.goBack() 
                })
                .catch(error=>{
                    console.log(error.response)
                    l.then(() => message.error(error.response.data.errors[0].msg, 2.5))
                })
        }
    }

   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.add()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
      fileChangedHandler = event => {
      this.setState({
        img: event.target.files[0],
        imgName: event.target.files[0].name,
        imgType:"data"
      })
    }
    removeFile = () => {
      this.setState({
          img:null,
          imgName:'',
          imgType:"url"
      });
    }
    theOptions(){
        return this.state.options.map((v, i) =>
            <MDBRow> 
                <MDBCol md="2">
                    {i>0&& 
                    <div className="minusInput" onClick={()=>this.removeOptionsInput(i)}>
                    <MDBIcon icon="trash" className="mr-2" />           
                    </div>
                    }
                    {i===this.state.options.length-1&& 
                    <div className="addInputInner"  onClick={()=>this.setState(prevState => ({ options: [...prevState.options, {option_en:'',option_ar: ''}]})) }>
                    <MDBIcon icon="plus" className="mr-2" />           
                    </div>
                    }
                </MDBCol>
                <MDBCol md="5">
                    <div className="md-form">
                    <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabic}</label>
                    
                    <input value={v.option_en} disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                    type="text" id="form2" name="" className="form-control" 
                    onChange={(event)=>{
                        let options = [...this.state.options];
                        options[i].option_en = event.target.value;
                        console.log("options",options)
                        this.setState({ options });
                    }} required validate/>
                    <div class="valid-feedback">{allStrings.correct}</div>
                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                    </div>
                </MDBCol>
                <MDBCol md="5">
                    <div className="md-form">
                        <label htmlFor="form2"style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicOption}</label>
                        <input value={v.option_ar} disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                        type="text" id="form2" name="" className="form-control" 
                        onChange={(event)=>{
                            let options = [...this.state.options];
                            options[i].option_ar = event.target.value;
                            console.log("options",options)
                            this.setState({ options });
                        }} required validate/>
                        <div class="valid-feedback">{allStrings.correct}</div>
                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                    </div>
                </MDBCol>
            </MDBRow>   

        )
    }
      
    removeOptionsInput = (i) =>{
        let options = [...this.state.options];
        console.log(i)
        options.splice(i,1);
        console.log("options",options)
        this.setState({ options });
    } 
   
  render(){
    console.log("type",this.state.type)
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('FEATURES')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#1D9598'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>star_outline</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{allStrings.AddFeature}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={true} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {true}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.category}
                                                showCheckedStrategy= {SHOW_PARENT}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({category:value});

                                                }} 
                                            
                                            >
                                                {this.state.categories.map(val=>(
                                                    <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                                                ))}
                                            </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.type}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({type:value});
                                                    if(value === "LIST"){
                                                        this.setState({inputCount:1});
                                                    }else{
                                                        this.setState({inputCount:0});
                                                    }
                                                }} 
                                            
                                            >
                                                <TreeNode value="LIST" title={allStrings.list} key="LIST" />
                                                <TreeNode value="NUMBER" title={allStrings.number} key="NUMBER" />
                                                <TreeNode value="BOOLEAN" title={allStrings.boolean} key="BOOLEAN" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.redfFeature}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false}
                                                disabled={this.state.actionType==="VIEW"?true:false} 
                                                value={this.state.redfKey}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.redfFeature}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({redfKey:value});
                                                }} 
                                            >
                                                <TreeNode value="direction" title="direction" key="direction" />
                                                <TreeNode value="width" title="width" key="width" />
                                                <TreeNode value="length" title="length" key="length" />
                                                <TreeNode value="livingRooms" title="livingRooms" key="livingRooms" />
                                                <TreeNode value="bedrooms"title="bedrooms" key="bedrooms" />
                                                <TreeNode value="bathrooms" title="bathrooms" key="bathrooms" />
                                                <TreeNode value="kitchens" title="kitchens" key="kitchens" />
                                                <TreeNode value="floorsInBuilding" title="floorsInBuilding" key="floorsInBuilding" />
                                                <TreeNode value="floorNumber" title="floorNumber" key="floorNumber" />
                                                <TreeNode value="buildYear" title="buildYear" key="buildYear" />
                                                <TreeNode value="isKitchenFurnished" title="isKitchenFurnished" key="isKitchenFurnished" />
                                                <TreeNode value="isFurnished" title="isFurnished" key="isFurnished" />
                                                <TreeNode value="hasAdditionalUnit" title="hasAdditionalUnit" key="hasAdditionalUnit" />
                                                <TreeNode value="hasElevator" title="hasElevator" key="hasElevator" />
                                                <TreeNode value="hasBasement" title="hasBasement" key="hasBasement" />
                                                <TreeNode value="hasAirConditioning" title="hasAirConditioning" key="hasAirConditioning" />
                                                <TreeNode value="parking" title="parking" key="parking" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.regaKey}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false}
                                                disabled={this.state.actionType==="VIEW"?true:false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.regaKey}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({regaKey:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value="Furnished" title="Furnished" key="Furnished" />
                                                <TreeNode value="Kitchen" title="Kitchen" key="Kitchen" />
                                                <TreeNode value="Air_Condition" title="Air_Condition" key="Air_Condition" />
                                                <TreeNode value="Facilities"title="Facilities" key="Facilities" />
                                                <TreeNode value="Floor_Number" title="Floor_Number" key="Floor_Number" />
                                                <TreeNode value="Rooms_Number" title="Rooms_Number" key="Rooms_Number" />
                                                <TreeNode value="Rooms_Type" title="Rooms_Type" key="Rooms_Type" />
                                                <TreeNode value="Real_Estate_Facade" title="Real_Estate_Facade" key="Real_Estate_Facade" />
                                                <TreeNode value="Street_Width" title="Street_Width" key="Street_Width" />
                                                <TreeNode value="Construction_Date" title="Construction_Date" key="Construction_Date" />
                                                <TreeNode value="East_Length" title="East_Length" key="East_Length" />
                                                <TreeNode value="West_Length" title="West_Length" key="West_Length" />
                                                <TreeNode value="South_Length" title="South_Length" key="South_Length" />
                                                <TreeNode value="noNth_leLgth" title="North_Length" key="North_Length" />
                                                <TreeNode value="Elevators" title="Elevators" key="Elevators" />
                                                <TreeNode value="Elevators_Number" title="Elevators_Number" key="Elevators_Number" />
                                                <TreeNode value="Parking" title="Parking" key="Parking" />
                                                <TreeNode value="Parking_Number" title="Parking_Number" key="Parking_Number" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        
                                    </MDBRow>
                                    {this.state.type==="LIST"&&this.theOptions()}
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="fileUpload2"> 
                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.img&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img alt="" src={URL.createObjectURL(this.state.img)}  className="previewImg2"/>
                                                
                                                <span className="imgName">{this.state.imgName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                            
                                        </MDBCol>
                                    </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn
                                        style={{background:'#1D9598',color:'#fff'}}
                                        rounded
                                        color="#1D9598"
                                        type="submit"
                                        className="btn-block z-depth-1a"
                                        >
                                        <MDBIcon icon="plus" className="mr-2" />
                                            {allStrings.add}          
                                        </MDBBtn>
                                       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(AddFeatures)
  );