import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './admin.css'
import {message} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import Input from '@material-ui/core/Input';
import {hasValue} from '../../validations/validations'


class Users extends React.Component {
  
  state={
    users:[],
    users2:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    search:""
  }

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    this.getUsers(1)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }


  getUsers = (page,refresh,search) =>{
    this.setState({loading:true})
    let url = ``
    if(search !== '' && search !== undefined){
      url = `${BASE_END_POINT}find?type=ADMIN&page=${page}&search=${search}`
    }else{
      url = `${BASE_END_POINT}find?type=ADMIN&page=${page}`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      
      let data = response.data.data.map(item=>[
        item.id,
        item.fullname?item.fullname.substr(0,15) :'',
        item.email?item.email:"",
        item.phone?item.phone:"",
        item.country?item.country.countryName:"",
        item.accountType==="ACTIVE"?{type:'tag',value:allStrings.active}:{type:'tag',value:allStrings.notActive} ,
        "action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        users:response.data.data,
        users2:data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }


  deleteUser = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}/${id}/delete`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
       'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("ROW DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getUsers(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="addUser">
        <Fab
          style={{background:'#1D9598',float:'left',margin:'50px 30px',width: '160px',borderRadius: '50px'}}
          letiant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getUsers(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getUsers(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getUsers(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} 
        style={{color:`#1D9598 !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getUsers(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getUsers(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  render() {
    if (this.props.currentUser.user.type !== "ADMIN") return <Redirect to='/Home' />

    return (
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          
          <Table
            reload = {
              <span 
              style={{position: 'absolute',margin: '7px 0px',color: '#1D9598',fontWeight: '600',cursor: 'pointer'}}
              onClick={() => this.getUsers(1)}
              className="material-icons">
                replay
              </span>
            }
            searchInput = {
              <Input 
              onKeyPress={(e)=>{
                if (e.key === 'Enter') {
                  if(hasValue(""+e.target.value)){
                    console.log("ENTER   ",e.target.value)
                    this.setState({search:e.target.value})
                    this.getUsers(1,e.target.value)
                  }else{
                    message.warning(allStrings.noValue);
                    console.log("ENTER NO VALUE   ",e.target.value)
                  }
                }
               }}  
              placeholder={allStrings.Search} style={{direction:this.props.isRTL?'rtl':'ltr'}}inputProps={{ 'aria-label': 'description' }} />
            }
            title={allStrings.adminsTable}
            icon='group'
            data={this.state.users}
            data2={this.state.users2}
            tableColumns={["#",allStrings.name,allStrings.email,allStrings.phone,allStrings.country,allStrings.accountType,allStrings.action]}loading={this.state.loading}
            deleteUser={(id)=>{this.deleteUser(id)}}
            path='/UserInfo'
            actionIndex={8}
            />
            {this.paginationButtons()}
            {/*this.addNewRecordButton()*/}
              
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    
    );
  }

}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Users)
);

