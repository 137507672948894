import React from 'react';
import './deal info.css'
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message ,Empty} from "antd";
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from 'react-loading-skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
class DealInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        status:this.props.location.state.data.status,
        type:this.props.location.state.data.type,
        totalInstallments:this.props.location.state.data.totalInstallments,
        paidInstallments:this.props.location.state.data.paidInstallments,
        cost:this.props.location.state.data.cost,
        advertisement:this.props.location.state.data.advertisement?this.props.location.state.data.advertisement:null,
        user:this.props.location.state.data.user?this.props.location.state.data.user:null,
        actionUser:this.props.location.state.data.actionUser?this.props.location.state.data.actionUser:null,
        pages:1,
        page:1,
        installments:[]
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        this.getInstallments(1)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getInstallments = (page) => {
        axios.get(`${BASE_END_POINT}deals/${this.state.advertisement.id}/installments/get?page=${page}`,{
          headers:{
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
          }
        })
        .then(response=>{
          console.log(response.data)
          this.setState({
            installments:response.data.data,
            page:parseInt(response.data.page),
            pages:response.data.pageCount
          })
        })
        .catch(error=>{
          console.log("ALL ads ERROR")
          console.log(error.response)
        })
      }
    
    delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}deal/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("Transaction DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.updateTransaction()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    paginationButtons = () =>(
        <CardActions style={{display: 'inline-flex'}}>
            <Button 
            onClick={()=>{
              if(this.state.page!==1){
                this.getInstallments(1)
                this.setState({page:1})
              }
            }}
            className="pagenation" >{allStrings.first}</Button>
    
            <Button 
            onClick={()=>{
              if(this.state.page>1){
                this.getInstallments(this.state.page-1)
                this.setState({page:this.state.page-1})
              }
            }}
            className="pagenation">{allStrings.prev}</Button>
            
            <Pagination
            onChange={(event,page)=>{
            console.log("page   ",page)
            if(page!==this.state.page){
              this.getInstallments(page)
              this.setState({page:page})
            }
            
            }} 
            defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#1D9598'} !important`}} />
            
            <Button 
            onClick={()=>{
              if(this.state.page<this.state.pages){
                this.getInstallments(this.state.page+1)
                this.setState({page:this.state.page+1})
              }
            }}
            className="pagenation">{allStrings.next}</Button>
    
            <Button 
              onClick={()=>{
              if(this.state.page!==this.state.pages){
                this.getInstallments(this.state.pages)
                this.setState({page:this.state.pages})
              }
            }}
            className="pagenation">{allStrings.last}</Button>
    
        </CardActions>
      )
    installmentsTable = () =>{
        return(
        <MDBRow>
            <MDBCol md="12">
                <div className="infoTable">
                    <div className="topInfoTable" style={{ background: '#1D9598' }}>
                        <h4>
                        <span class="material-icons" style={{color:'white',fontSize:23}}>featured_video</span> {allStrings.installments}
                        </h4>
                    </div>
                    <div className="tableDate">
                        <div className="table-responsive">
                            <table className="table table-striped">
                            <thead>
                              {this.props.isRTL?
                                <tr style={{textAlign:'center'}}>
                                    <th>{allStrings.more}</th>
                                    <th scope="col">{allStrings.status}</th>
                                    <th scope="col">{allStrings.totalCost}</th>
                                    <th scope="col">{allStrings.tax}</th>
                                    <th scope="col">{allStrings.cost}</th>
                                    <th scope="col">{allStrings.installmentNumber}</th>
                                    <th scope="col">#</th>
                                </tr>
                                    :
                                <tr style={{textAlign:'center'}}>
                                    <th scope="col">#</th>
                                    <th scope="col">{allStrings.installmentNumber}</th>
                                    <th scope="col">{allStrings.cost}</th>
                                    <th scope="col">{allStrings.tax}</th>
                                    <th scope="col">{allStrings.totalCost}</th>
                                    <th scope="col">{allStrings.status}</th>
                                    <th>{allStrings.more}</th>
                                </tr>
                              }
                                  
                            </thead>
                            <tbody>
                            {this.state.loading?
                                <tr style={{height: '200px'}}>
                                    <th colSpan="6" className="noData">
                                    <Skeleton height={400}/> 
                                    </th>
                                </tr>
                                :
                                this.state.installments.length > 0 ?
                                this.state.installments.map(val =>(
                                  this.props.isRTL?
                                  <tr style={{textAlign:'center'}}>
                                      <td onClick={()=>{this.props.history.push("installmentInfo",{data:val}) }} >
                                      <span style={{margin: "4px",cursor:'pointer'}}>
                                          <Tooltip title={allStrings.info} placement="top">
                                          <Info style={{color:'#33d633'}}/>
                                          </Tooltip>
                                      </span>
                                      </td>
                                      <th>{val.status==="PENDING"?allStrings.pending:val.status==="REQUIRED"?allStrings.required:allStrings.paid}</th>
                                      <td>{val.totalCost}</td>
                                      <td>{val.tax}</td>
                                      <td>{val.cost}</td>
                                      <th>{val.installmentNumber}</th>
                                      <td>{val.id}</td>
                                  </tr>
                                  :
                                  <tr style={{textAlign:'center'}}>
                                      <td>{val.id}</td>
                                      <th>{val.installmentNumber}</th>
                                      <td>{val.cost}</td>
                                      <td>{val.tax}</td>
                                      <td>{val.totalCost}</td>
                                      <th>{val.status==="PENDING"?allStrings.pending:val.status==="REQUIRED"?allStrings.required:allStrings.paid}</th>
                                      <td onClick={()=>{this.props.history.push("installmentInfo",{data:val}) }} >
                                      <span style={{margin: "4px",cursor:'pointer'}}>
                                          <Tooltip title={allStrings.info} placement="top">
                                          <Info style={{color:'#33d633'}}/>
                                          </Tooltip>
                                      </span>
                                      </td>
                                  </tr>
                                    ))
                
                                    :
                                    <tr style={{height: '200px'}}>
                                        <th colSpan="6" className="noData">
                                        <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                        </th>
                                    </tr>
                                }
                            </tbody>
                            </table>
                            {this.state.installments.length > 0 &&
                                <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                    {this.paginationButtons()}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </MDBCol>
        </MDBRow>
        )
      }
    
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CONTACT-REQUEST')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12" >
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#1D9598'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>content_paste</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.cost}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.cost} type="text" id="form8" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.totalInstallments}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.totalInstallments} type="text" id="form8" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.paidInstallments}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.paidInstallments} type="text" id="form8" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false}   style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.type==="ONLINE"?allStrings.onApp:allStrings.outApp} type="text" id="form645" name="type" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.status==="BOOKED"?allStrings.booked:this.state.status==="ACTIVE"?allStrings.active:allStrings.completed} type="text" id="form56" name="status" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    {this.state.advertisement&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.theAdveristment}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.advertisement?this.state.advertisement.title:'مشاهده الاعلان'} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('adsInfo',{data:{id:this.state.advertisement.id}}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('adsInfo',{data:{id:this.state.advertisement.id}}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.user&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.client}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user?this.state.user.fullname:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user?this.state.user.phone:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionUser &&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.actionUser}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.actionUser?this.state.actionUser.fullname:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.actionUser?this.state.actionUser.phone:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.actionUser}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.actionUser}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                 
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                    
                                    {/* {this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                            {allStrings.remove}
                                        </MDBBtn>
                                    } */}
                                        
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol> 
            </MDBRow>
            {this.state.actionType==="VIEW"&&
                this.installmentsTable()
            }
        </MDBContainer>
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(DealInfo)
  );
