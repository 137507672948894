import React from 'react';
import './client info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message} from "antd";
import moment from 'moment';
class clientInfo extends React.Component {
    state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
      page:1,
      pages:1,
      id:this.props.location.state.data.id,
      clientName:this.props.location.state.data.clientName?this.props.location.state.data.clientName:'',
      clientPhone:this.props.location.state.data.clientPhone?this.props.location.state.data.clientPhone:'',
      clientCountry:this.props.location.state.data.clientCountry?this.props.location.state.data.clientCountry.countryName:null,
      createdAt:this.props.location.state.data.createdAt,
      city:this.props.location.state.data.city?this.props.location.state.data.city.cityName:null,
      area:this.props.location.state.data.area?this.props.location.state.data.area.areaName:null,
      category:this.props.location.state.data.category?this.props.location.state.data.category.categoryName:null,
      subCategory:this.props.location.state.data.subCategory?this.props.location.state.data.subCategory.categoryName:null,
      contractType:this.props.location.state.data.contractType,
      status:this.props.location.state.data.status,
      user:this.props.location.state.data.user,
      notes:this.props.location.state.data.notes?this.props.location.state.data.notes:"",
      commission:this.props.location.state.data.commission,
      statusReason:this.props.location.state.data.statusReason,
      modal:false,
      closeType:'DEAL-DONE'
      };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
      this.getClientData()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getClientData = () => {
      axios.get(`${BASE_END_POINT}clients/${this.state.id}`,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
        }})
      .then(response=>{
          console.log('client  ',response.data.data)
          this.setState({
            loading:false,
            clientName:response.data.data.clientName,
            clientPhone:response.data.data.clientPhone,
            clientCountry:response.data.data.clientCountry?response.data.data.clientCountry.countryName:null,
            createdAt:response.data.data.createdAt,
            user:response.data.data.user,
            notes:response.data.data.notes?response.data.data.notes:"",
            contractType:response.data.data.contractType,
            status:response.data.data.status,
            commission:response.data.data.commission,
            city:response.data.data.city?response.data.data.city.cityName:null,
            area:response.data.data.area?response.data.data.area.areaName:null,
            category:response.data.data.category?response.data.data.category.categoryName:null,
            subCategory:response.data.data.subCategory?response.data.data.subCategory.categoryName:null,
            statusReason:response.data.data.statusReason?response.data.data.statusReason:" ",
          })
          this.getCountry(response.data.data.clientCountry?response.data.data.clientCountry.id:0)
      })
      .catch(error=>{
          console.log('theClient ERROR  ',error)
          console.log('theClient ERROR  ',error.respose)
      })
    }
    getCountry = (id) => {
      axios.get(`${BASE_END_POINT}countries/${id}`)
      .then(response=>{
        console.log("country",response.data.data)
        this.setState({
          phone:this.state.phone.replace(response.data.data.countryCode, "")
        })
      })
      .catch(error=>{
        console.log(" country ERROR")
        console.log(error.response)
      })
    }
    delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}clients/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en',
         },
        })
        .then(response=>{
          console.log("client DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    onProgress = () => {
        let l = message.loading('Wait..', 2.5)
        axios.put(`${BASE_END_POINT}clients/${this.props.location.state.data.id}/onProgress`,{},{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en',
        },
        })
        .then(response=>{
          console.log("client accept")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    dealDone = () => {
      let l = message.loading('Wait..', 2.5)
      if(this.state.closeType === "DEAL-DONE" && !this.state.commission){
        l.then(() => message.success(allStrings.commissionRequired, 2.5) )
      }
      axios.put(`${BASE_END_POINT}clients/${this.props.location.state.data.id}/deal`,{
        commission:this.state.commission,
        statusReason:this.state.statusReason
      },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      },
      })
      .then(response=>{
        console.log("client accept")
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    dealFailed = () => {
      let l = message.loading('Wait..', 2.5)
      axios.put(`${BASE_END_POINT}clients/${this.props.location.state.data.id}/failed`,{
        statusReason:this.state.statusReason
      },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      },
      })
      .then(response=>{
        console.log("client accept")
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
    }

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    submitHandler = event => {
      event.preventDefault();
      console.log(event.target.className)
      event.target.className += " was-validated";
      if(this.state.closeType === "DEAL-DONE"){
        this.dealDone()
      }else{
        this.dealFailed()
      }
      
    };
    
    toggle = () => {
      this.setState({
          modal: !this.state.modal,
      });
    }
    modal = () =>(
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                      {this.state.closeType === "DEAL-DONE"&&
                        <MDBCol md="12">
                          <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.commission}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={0} id="form92" className="form-control" onChange={this.changeHandler} required validate  name="commission" defaultValue={this.state.commission}/>
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                          </div>
                        </MDBCol>
                      }
                        <MDBCol md="12">
                        <div className="md-form">
                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.statusReason}</label>
                          <br></br>
                          <textarea name="statusReason" value={this.state.statusReason} onChange={this.changeHandler} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control"  validate required   />
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                        </MDBCol>
                      </MDBRow>
                    
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                        {allStrings.done}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CLIENTS')) return <Redirect to='/Home' />
  return (
    <div className="App">
        <MDBContainer> 
        {this.modal()}
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                          <CardMedia
                            style={{height:"0px"}}
                          />
                            <CardHeader></CardHeader>
                            <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                <div className="topIcon" style={{background:'#1D9598'}}>
                                    <span class="material-icons" style={{color:'white',fontSize:23}}>contact_phone</span>
                                </div>
                                <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                                marginLeft:'0',marginRight:'0'}}>
                                <p>{allStrings.info}</p>
                                </div>
                            </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    <div className="grey-text">
                                   {this.state.actionType==="VIEW"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form6015" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form6015"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <div className="md-form">
                                            <label htmlFor="form6005" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.status === "PENDING"?allStrings.pending:this.state.status === "ON-PROGRESS"?allStrings.dealOnProgress:this.state.status === "DEAL"?allStrings.dealDone:allStrings.dealFailed} type="text" id="form6005"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                        </MDBCol>
                                        
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.clientName}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.clientName} type="text" id="form645" name="clientName" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.clientPhone}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.clientPhone} type="text" id="form56" name="clientPhone" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form516" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.city}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.city} type="text" id="form516" name="city" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form625" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.area}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.area} type="text" id="form625" name="area" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form516" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.city} type="text" id="form516" name="city" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form625" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.subCategory}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.subCategory} type="text" id="form625" name="subCategory" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.contractType}</label>
                                            <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.contractType === "SALE"?allStrings.sale:allStrings.rent} type="text" id="form56" name="clientPhone" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div class="md-form">
                                            <label for="form9220" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.notes}</label>
                                            <br></br>
                                            <textarea disabled={this.state.actionType==="EDIT"?false:true}  value={this.state.notes} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 0'}} type="text" id="form9220" class="form-control"  validate  name="notes"/>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    {this.state.actionType==="VIEW"&&this.state.status==="DEAL"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.commission}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.commission} type="text" id="form605"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="VIEW"&&(this.state.status==="DEAL" ||this.state.status==="FAILED")&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.statusReason}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.statusReason} type="text" id="form605"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="VIEW"&& this.state.createdAt&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form062" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={moment(this.state.createdAt).format('LLL')} type="text"  id="form062"  className="form-control"  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="VIEW"&&
                                      <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                          <MDBCol md="10" xs="10">
                                              <div class="md-form">
                                              <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.addedBy}</label>
                                              <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user?this.state.user.fullname:''} type="text" id="form80" class="form-control"  />
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="2" xs="2">
                                        
                                          <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                              <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                              onClick={()=>{this.props.history.push('userInfo',{data:this.state.user,actionType:'VIEW'}) }}>
                                                remove_red_eye
                                              </span>
                                              <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                                onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                                {allStrings.show}
                                              </span> 
                                            </p>
                                          </MDBCol>
                                      </MDBRow>
                                    }
                                    

                                    
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                      {this.state.actionType==="VIEW"&&this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                            {allStrings.remove}
                                        </MDBBtn>
                                      }
                                      {this.state.status ==="PENDING"&&this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.onProgress()}} rounded color="green" className="btn-block z-depth-1a">
                                            <MDBIcon  className="mr-2" />
                                            {allStrings.dealOnProgress}
                                        </MDBBtn>
                                      }
                                      {this.state.status ==="ON-PROGRESS"&&this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.toggle();this.setState({closeType:"DEAL-DONE"})}} rounded  className="btn-block z-depth-1a">
                                            <MDBIcon  className="mr-2" />
                                            {allStrings.dealDone}
                                        </MDBBtn>
                                      }
                                      {this.state.status ==="ON-PROGRESS"&&this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.toggle();this.setState({closeType:"DEAL-FAILED"})}} rounded color="warning" className="btn-block z-depth-1a">
                                            <MDBIcon  className="mr-2" />
                                            {allStrings.dealFailed}
                                        </MDBBtn>
                                      }
                                        
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
            
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(clientInfo)
  );