import React from 'react';
import './installment info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBIcon,MDBBtn,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message } from "antd";
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';

class InstallmentInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        user:this.props.location.state.data.user?this.props.location.state.data.user:null,
        actionUser:this.props.location.state.data.actionUser?this.props.location.state.data.actionUser:null,
        installmentNumber:this.props.location.state.data.installmentNumber,
        status:this.props.location.state.data.status,
        type:this.props.location.state.data.type,
        advertisement:this.props.location.state.data.advertisement?this.props.location.state.data.advertisement:null,
        cost:this.props.location.state.data.cost,
        tax:this.props.location.state.data.tax?this.props.location.state.data.tax:0,
        totalCost:this.props.location.state.data.totalCost?this.props.location.state.data.totalCost:this.props.location.state.data.cost,
        paidDate:this.props.location.state.data.paidDate?this.props.location.state.data.paidDate:null,
        dueDate:this.props.location.state.data.dueDate?this.props.location.state.data.dueDate:null,
        proofPdf:null,
        proofPdfType:'data'

    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    
    payInstallment = () => {
        const {proofPdf} = this.state
        if(proofPdf !== null){
            let l = message.loading('Wait..', 2.5)
            let data = new FormData()
            data.append('proofPdf',proofPdf)
            console.log(Array.from(data))
            axios.put(`${BASE_END_POINT}deals/${this.props.location.state.data.id}/payInstallment`,data,{
                headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':`Bearer ${this.props.currentUser.token}`,
                'Accept-Language':this.props.isRTL?'ar':'en'
                },
            })
            .then(response=>{
                l.then(() => message.success(allStrings.done, 2.5) )
                this.toggle()
                this.props.history.goBack()
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
      }
    submitHandlerPayInstallment = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.payInstallment()
    };
    changeHandlerPayInstallment = (event) => {
        console.log(event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }
    modal = () =>(
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
            <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
            <MDBModalBody>
                <MDBContainer>
                <MDBRow>
                    <MDBCol md="12">
                    <form style={{paddingTop:'1rem'}}
                        className="needs-validation"
                        onSubmit={this.submitHandlerPayInstallment}
                        noValidate
                    >
                        <div className="grey-text">
                        <MDBRow>
                            <MDBCol md="12">
                                <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.proofPdf}</label>
                                <div className="fileUpload2"> 
                                    <input  key={this.state.proofPdf} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                    <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.uploadFile}</span></span>
                                </div>
                                {this.state.proofPdf&&
                                <div className="preview">
                                    <div style={{ display: "inline-block" }}>
                                        <p className="previewPdf">{this.state.proofPdf.name}
                                            <MDBIcon icon="times" className="mr-2 removePdf"
                                            onClick={() => this.removeFile()}  ></MDBIcon>
                                        </p>
                                    </div>
                                </div>
                                }
                            </MDBCol>
                            
                        </MDBRow>
                        </div>
                        <br></br>
                        <br></br>
                        <div className="text-center">
                        <MDBBtn disabled={this.state.proofPdf?false:true} type="submit" style={{ background: '#1D9598' }} rounded className="btn-block z-depth-1a" >
                            {allStrings.payInstallment}
                        </MDBBtn>
                        </div>
                    </form>
                    </MDBCol>
                </MDBRow>
                </MDBContainer>
            </MDBModalBody>
        </MDBModal>
    );
    fileChangedHandler = event => {
        console.log(event.target.files[0])
        this.setState({
            proofPdf: event.target.files[0],
            proofPdfType:'data'
        })
    }
    removeFile = () => {
        this.setState({
            proofPdf:null,
            proofPdfType:'url'
            
        });
    }
   
    
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('installmentS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            {this.modal()}
            <MDBRow>  
                <MDBCol md="12" >
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#1D9598'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>content_paste</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control" validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.installmentNumber}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false}   style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.installmentNumber} type="text" id="form645" name="installmentNumber" className="form-control" required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.status==="PENDING"?allStrings.pending:this.state.status==="REQUIRED"?allStrings.required:allStrings.paid} type="text" id="form56" name="status" className="form-control" required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                        <div className="md-form">
                                          <label htmlFor="formn920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.type?this.state.type==="NORMAL"?allStrings.achievementInstallment:allStrings.taxInstallment:""} type="text" id="formn920" name="type" className="form-control" required validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.cost}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.cost} type="text" id="form920" name="cost" className="form-control" required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="formn930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.tax}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.tax} type="text" id="formn930" name="tax" className="form-control" required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form940" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.totalCost}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.totalCost} type="text" id="form940" name="totalCost" className="form-control" required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div class="md-form">
                                            <label htmlFor="form6015" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.paidDate}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.paidDate?moment(this.state.paidDate).format('MMMM Do YYYY, h:mm a'):"لم يتم الدفع"} type="text" id="form6015"  class="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    {this.state.advertisement&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.advertisement}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.advertisement?this.state.advertisement:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('adsInfo',{data:{id:this.state.advertisement.id}}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('adsInfo',{data:{id:this.state.advertisement.id}}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.user&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="10" xs="10">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.client}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user?this.state.user.fullname:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionUser &&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.actionUser}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.actionUser?this.state.actionUser.fullname:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="5" xs="5">
                                            <div class="md-form">
                                            <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.actionUser?this.state.actionUser.phone:''} type="text" id="form80" class="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="2" xs="2">
                                    
                                        <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                            <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.actionUser}) }}>
                                            remove_red_eye
                                            </span>
                                            <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                            onClick={()=>{this.props.history.push('userInfo',{data:this.state.actionUser}) }}>
                                            {allStrings.show}
                                            </span> 
                                        </p>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.status != "PAID"&&
                                    <MDBBtn onClick={()=>{this.toggle()}} rounded className="btn-block z-depth-1a">
                                        {allStrings.payInstallment}
                                    </MDBBtn>
                                    }
                                 
                                    </div>
                                    <br></br>
                                    <br></br>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol> 
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(InstallmentInfo)
  );