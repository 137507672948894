import React from 'react';
import './package info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import Skeleton from 'react-loading-skeleton';
import Pagination from '@material-ui/lab/Pagination';
import { message,TreeSelect ,Empty} from "antd";
const TreeNode = TreeSelect

class packageInfo extends React.Component {
    state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
      page:1,
      pages:1,
        id:this.props.location.state.data.id,
        description_ar:this.props.location.state.data.description_ar,
        name_en:this.props.location.state.data.name_en,
        name_ar:this.props.location.state.data.name_ar,
        type:this.props.location.state.data.type,
        supervisors:this.props.location.state.data.supervisors,
        available:this.props.location.state.data.available,
        default:this.props.location.state.data.defaultPackage,
        plan:this.props.location.state.data.plan,
        booking:this.props.location.state.data.booking,
        building:this.props.location.state.data.building,
        usersCount:this.props.location.state.data.usersCount,
        availableAds:this.props.location.state.data.availableAds,
        availableProperty:this.props.location.state.data.availableProperty,
        description_en:this.props.location.state.data.description_en,
        costs:this.props.location.state.data.costs,
        cost1:this.props.location.state.data.costs[0]?this.props.location.state.data.costs[0].cost:0,
        duration1:this.props.location.state.data.costs[0]?this.props.location.state.data.costs[0].duration:0,
        durationType1:this.props.location.state.data.costs[0]?this.props.location.state.data.costs[0].durationType:'YEARLY',
        cost2:this.props.location.state.data.costs[1]?this.props.location.state.data.costs[1].cost:0,
        duration2:this.props.location.state.data.costs[1]?this.props.location.state.data.costs[1].duration:0,
        durationType2:this.props.location.state.data.costs[1]?this.props.location.state.data.costs[1].durationType:'YEARLY',
        cost3:this.props.location.state.data.costs[2]?this.props.location.state.data.costs[2].cost:0,
        duration3:this.props.location.state.data.costs[2]?this.props.location.state.data.costs[2].duration:0,
        durationType3:this.props.location.state.data.costs[2]?this.props.location.state.data.costs[2].durationType:'YEARLY',
        users:[]
      };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
      this.getUsers()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    update = () => {
        const {name_en,name_ar,availableAds,description_en,building,booking,availableProperty,
        description_ar,type,plan,supervisors,duration1,durationType1,cost1
        ,durationType2,cost2,duration2} = this.state
        
        
        if(hasValue(name_en)&&hasValue(name_ar)&&hasValue(description_en)&&hasValue(description_ar)){
          let l = message.loading('Wait..', 2.5)
            const data={
                'name_en':name_en,
                'name_ar':name_ar,
                'description_ar':description_ar,
                'description_en':description_en,
                'supervisors':supervisors,
                'availableAds':availableAds,
                'type':type,
                'plan':plan,
                'building':building,
                'booking':booking,
                'availableProperty':availableProperty
            }
            
            let costs = []
            if(durationType1 != null){
                costs.push({duration:duration1,durationType:durationType1,cost:cost1})
            }
            if(durationType2 != null){
                costs.push({duration:duration2,durationType:durationType2,cost:cost2})
            }
            data.costs = costs
            axios.put(`${BASE_END_POINT}packages/${this.props.location.state.data.id}`,JSON.stringify(data),{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("package UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                if(error.response.data.errors[0].msg){
                    l.then(() => message.error(error.response.data.errors[0].msg, 2.5))

                }else{
                    l.then(() => message.error(allStrings.error, 2.5))

                }
            })
        }
    }

    delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}packages/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("package DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
   
    beDefault = () => {
      let l = message.loading('Wait..', 2.5)
      
      axios.put(`${BASE_END_POINT}packages/${this.props.location.state.data.id}/beDefault`,{},{
       headers: {
         'Content-Type': 'application/json',
         'Authorization':`Bearer ${this.props.currentUser.token}`,
         'Accept-Language':this.props.isRTL?'ar':'en'
       },
      })
      .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.err, 2.5))
      })
   }
  available = () => {
    let l = message.loading('Wait..', 2.5)
    
    axios.put(`${BASE_END_POINT}packages/${this.props.location.state.data.id}/available`,{},{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
       'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
      this.setState({available: true})
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.err, 2.5))
    })
  }
  disAvailable = () => {
    let l = message.loading('Wait..', 2.5)
    
    axios.put(`${BASE_END_POINT}packages/${this.props.location.state.data.id}/disAvailable`,{},{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
       'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
      this.setState({available: false})
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.err, 2.5))
    })
  }
   getUsers = (page,refresh) =>{
    this.setState({loading:true})
    let url=`${BASE_END_POINT}find?adsPackage=${this.state.id}&page=${page}`
    if(this.state.type === "PROPERTY"){
      url=`${BASE_END_POINT}find?propertyPackage=${this.state.id}&page=${page}`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      
      this.setState({
        loading:false,
        refresh:false,
        users:response.data.data,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.update()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
      
  
    paginationButtons = () =>(
      <CardActions style={{display: 'inline-flex'}}>
          <Button 
          onClick={()=>{
            if(this.state.page!==1){
              this.getUsers(1)
              this.setState({page:1})
            }
          }}
          className="pagenation" >{allStrings.first}</Button>
  
          <Button 
          onClick={()=>{
           if(this.state.page>1){
              this.getUsers(this.state.page-1)
              this.setState({page:this.state.page-1})
           }
          }}
          className="pagenation">{allStrings.prev}</Button>
          
          <Pagination
          onChange={(event,page)=>{
          console.log("page   ",page)
          if(page!==this.state.page){
            this.getUsers(page)
            this.setState({page:page})
          }
          
          }} 
          defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#1D9598 !important`}} />
          
          <Button 
          onClick={()=>{
            if(this.state.page<this.state.pages){
              this.getUsers(this.state.page+1)
              this.setState({page:this.state.page+1})
            }
          }}
          className="pagenation">{allStrings.next}</Button>
  
          <Button 
           onClick={()=>{
            if(this.state.page!==this.state.pages){
              this.getUsers(this.state.pages)
              this.setState({page:this.state.pages})
            }
          }}
          className="pagenation">{allStrings.last}</Button>
  
      </CardActions>
    )
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('PACKAGES')) return <Redirect to='/Home' />
  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#1D9598'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>list_alt</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.PackageInfo:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form062" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.usersCount}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.usersCount} type="number" min={0} id="form062" name="cost" className="form-control" disabled validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form605"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form162" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.available}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.available?allStrings.yes:allStrings.no}  id="form162" name="active" className="form-control" disabled validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form625" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.default}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.default?allStrings.yes:allStrings.no} type="text" id="form625"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                      <MDBCol md="12">
                                        <div className="md-form">
                                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishDescription}</label>
                                          <br></br>
                                          <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  name="description_en"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate  onChange={this.changeHandler} defaultValue={this.state.description_en} />
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                      <MDBCol md="12">
                                        <div className="md-form">
                                          <label htmlFor="form90" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicDescription}</label>
                                          <br></br>
                                          <textarea disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="description_ar"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form90" className="form-control" required validate onChange={this.changeHandler}  defaultValue={this.state.description_ar} />
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    {this.state.type==="ADS" &&
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form569" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.availableAds}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={1} value={this.state.availableAds}  id="form569" name="availableAds" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                            <label htmlFor="form90" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.supervisors}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} name="supervisors" onChange={this.changeHandler} type="number" min={0} value={this.state.supervisors } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} id="form90" className="form-control" required validate   />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                      
                                    </MDBRow>
                                    }
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                disabled={this.state.actionType==="VIEW"?true:false}
                                                value={this.state.type}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.type}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({type:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value="ADS" title={allStrings.adsManagment} key="ADS" />
                                                <TreeNode value="PROPERTY" title={allStrings.propertyManagement} key="PROPERTY" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        {this.state.type==="ADS" ?
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.plan}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                disabled={this.state.actionType==="VIEW"?true:false}
                                                value={this.state.plan}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.plan}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({plan:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value={true} title={allStrings.yes} key="true" />
                                                <TreeNode value={false} title={allStrings.no} key="false" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        :
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form569" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.availableProperty}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={1} value={this.state.availableProperty}  id="form569" name="availableProperty" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        }
                                    </MDBRow>
                                    {this.state.type==="ADS"&&
                                      <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.building}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                disabled={this.state.actionType==="VIEW"?true:false}
                                                value={this.state.building}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.building}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({building:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value={true} title={allStrings.yes} key="true" />
                                                <TreeNode value={false} title={allStrings.no} key="false" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6"></MDBCol>
                                      </MDBRow>
                                    }
                                    {this.state.type!=="ADS"&&
                                      <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.bookings}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                disabled={this.state.actionType==="VIEW"?true:false}
                                                value={this.state.booking}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.bookings}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({booking:value});
                                                }} 
                                            
                                            >
                                                <TreeNode value={true} title={allStrings.yes} key="true" />
                                                <TreeNode value={false} title={allStrings.no} key="false" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form569" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.availableAds}</label>
                                            <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={1} value={this.state.availableAds}  id="form569" name="availableAds" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                      </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="4">
                                            <div className="md-form">
                                              <label htmlFor="form00" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.duration}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} name="duration1" onChange={this.changeHandler} type="number" min={0} value={this.state.duration1 } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} id="form000" className="form-control" required validate   />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <div className="md-form">
                                              <label htmlFor="form000" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.cost}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} name="cost1" onChange={this.changeHandler} type="number" min={0} value={this.state.cost1 } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} id="form90" className="form-control" required validate   />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.durationType}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                value={this.state.durationType1}
                                                disabled={this.state.actionType==="VIEW"?true:false}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.durationType}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({durationType1:value});
                                                }} 
                                            
                                            >
                                                {/*<TreeNode value="DAILY" title={allStrings.daily} key="DAILY" />*/}
                                                <TreeNode value="MONTHLY" title={allStrings.monthly} key="MONTHLY" />
                                                <TreeNode value="YEARLY" title={allStrings.yearly} key="YEARLY" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                     
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="4">
                                            <div className="md-form">
                                              <label htmlFor="form00" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.duration}</label>
                                              <input name="duration2" disabled={this.state.actionType==="VIEW"?true:false} onChange={this.changeHandler} type="number" min={0} value={this.state.duration2 } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} id="form000" className="form-control" validate   />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <div className="md-form">
                                              <label htmlFor="form000" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.cost}</label>
                                              <input name="cost2" disabled={this.state.actionType==="VIEW"?true:false} onChange={this.changeHandler} type="number" min={0} value={this.state.cost2 } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} id="form90" className="form-control" validate   />
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.durationType}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                disabled={this.state.actionType==="VIEW"?true:false}
                                                value={this.state.durationType2}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.durationType}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({durationType2:value});
                                                }} 
                                            
                                            >
                                                {/*<TreeNode value="DAILY" title={allStrings.daily} key="DAILY" />*/}
                                                <TreeNode value="MONTHLY" title={allStrings.monthly} key="MONTHLY" />
                                                <TreeNode value="YEARLY" title={allStrings.yearly} key="YEARLY" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                    </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                      {this.state.actionType==="EDIT"&&
                                        <MDBBtn
                                        style={{background:'#1D9598',color:'#fff'}}
                                        rounded
                                        color="#1D9598"
                                        type="submit"
                                        className="btn-block z-depth-1a"
                                        >
                                            <MDBIcon icon="edit" className="mr-2" />
                                            {allStrings.update}          
                                        </MDBBtn>
                                      }{this.state.actionType==="VIEW"&&
                                      <MDBRow>
                                        <MDBCol md="6">
                                          <MDBBtn
                                            rounded
                                            color="info"
                                            style={{ borderRadius: "2px" }}
                                            className="btn-block z-depth-1a"
                                            onClick={()=>{
                                              this.available()
                                            }}
                                          >
                                            <MDBIcon icon="check-circle" className="mr-2" />
                                            {allStrings.availablee}
                                          </MDBBtn>
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <MDBBtn
                                            rounded
                                            color="warning"
                                            style={{ borderRadius: "2px" }}
                                            className="btn-block z-depth-1a"
                                            onClick={()=>{
                                              this.disAvailable()
                                            }}
                                          >
                                            <MDBIcon icon="ban" className="mr-2" />
                                            {allStrings.disAvailable}
                                          </MDBBtn>
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                                              <MDBIcon icon="trash-alt" className="mr-2" />
                                              {allStrings.remove}
                                          </MDBBtn>
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <MDBBtn onClick={()=>{this.beDefault()}} rounded color="warning" className="btn-block z-depth-1a">
                                              <MDBIcon  className="mr-2" />
                                              {allStrings.beDefault}
                                          </MDBBtn>
                                        </MDBCol>
                                      </MDBRow>
                                        
                                      }

                                        
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
          {this.state.actionType==="VIEW"&&
            <MDBRow>
                <MDBCol md="12">
                    <div className="infoTable">
                        <div className="topInfoTable" style={{ background: "#1D9598" }}>
                            <h4>
                            <span className="material-icons" style={{color:'white',fontSize:23}}>group</span>{ allStrings.users}
                            </h4>
                        </div>
                        <div className="tableDate">
                        <div className="table-responsive">
                            <table className="table table-striped">
                            <thead>
                              {this.props.isRTL?
                                <tr style={{textAlign:'center'}}>
                                    <th>{allStrings.more}</th>
                                    <th>{allStrings.email}</th>
                                    <th>{allStrings.phone}</th>
                                    <th>{allStrings.name}</th>
                                    <th scope="col">#</th>
                                </tr>
                                :
                                <tr style={{textAlign:'center'}}>
                                      <th scope="col">#</th>
                                      <th>{allStrings.name}</th>
                                      <th>{allStrings.email}</th>
                                      <th>{allStrings.phone}</th>
                                      <th>{allStrings.more}</th>
                                  </tr>
                              }
                                
                            </thead>
                            <tbody>
                            {this.state.loading?
                                <tr style={{height: '200px'}}>
                                    <th colSpan={"5"} className="noData">
                                    <Skeleton height={400}/> 
                                    </th>
                                </tr>
                                :
                                this.state.users.length > 0 ?
                                this.state.users.map(val =>(
                                  
                                  this.props.isRTL?
                                  <tr  style={{textAlign:'center'}}>
                                     <td
                                      onClick={()=>{this.props.history.push('/userInfo',{data:val}) }} 
                                      >
                                      <span style={{margin: "4px",cursor:'pointer'}}>
                                          <Tooltip title={allStrings.info} placement="top">
                                          <Info style={{color:'#33d633'}}/>
                                          </Tooltip>
                                      </span>
                                      </td>
                                      <td>{val.email?val.email:""}</td>
                                      <td>{val.phone?val.phone:""}</td>
                                      <td>{val.fullname}</td>
                                      <td >{val.id}</td>
                                  </tr>
                                  :
                                  <tr  style={{textAlign:'center'}}>
                                      <td >{val.id}</td>
                                      <td>{val.fullname}</td>
                                      <td>{val.email?val.email:""}</td>
                                      <td>{val.phone?val.phone:""}</td>
                                      <td
                                      onClick={()=>{this.props.history.push('/userInfo',{data:val}) }} 
                                      >
                                      <span style={{margin: "4px",cursor:'pointer'}}>
                                          <Tooltip title={allStrings.info} placement="top">
                                          <Info style={{color:'#33d633'}}/>
                                          </Tooltip>
                                      </span>
                                      </td>
                                  </tr>
                               
                                 ))
               
                                 :
                                 <tr style={{height: '200px'}}>
                                     <th colSpan="5" className="noData">
                                     <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                     </th>
                                 </tr>
                               }
                              
                               
                                
                            </tbody>
                            </table>
                            {this.state.users.length > 0 &&
                                <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                    {this.paginationButtons()}
                                </div>
                            }
                        </div>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
          }
            
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(packageInfo)
  );
