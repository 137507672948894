import React from 'react';
import './notif info.css'
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import Skeleton from 'react-loading-skeleton';
import Pagination from '@material-ui/lab/Pagination';
import {Empty} from "antd";
class notifInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        page:1,
        pages:1,
        id:this.props.location.state.data.id,
        description:this.props.location.state.data.description,
        title:this.props.location.state.data.title,
        subjectId:this.props.location.state.data.deepId,
        usersCount:this.props.location.state.data.usersCount,
        users:[]
      };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
      this.getUsers()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
   getUsers = (page,refresh) =>{
    this.setState({loading:true})
    let url=`${BASE_END_POINT}find?marketingNotif=${this.state.id}&page=${page}`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      this.setState({
        loading:false,
        refresh:false,
        users:response.data.data,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
    paginationButtons = () =>(
      <CardActions style={{display: 'inline-flex'}}>
          <Button 
          onClick={()=>{
            if(this.state.page!==1){
              this.getUsers(1)
              this.setState({page:1})
            }
          }}
          className="pagenation" >{allStrings.first}</Button>
  
          <Button 
          onClick={()=>{
           if(this.state.page>1){
              this.getUsers(this.state.page-1)
              this.setState({page:this.state.page-1})
           }
          }}
          className="pagenation">{allStrings.prev}</Button>
          
          <Pagination
          onChange={(event,page)=>{
          console.log("page   ",page)
          if(page!==this.state.page){
            this.getUsers(page)
            this.setState({page:page})
          }
          
          }} 
          defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#1D9598 !important`}} />
          
          <Button 
          onClick={()=>{
            if(this.state.page<this.state.pages){
              this.getUsers(this.state.page+1)
              this.setState({page:this.state.page+1})
            }
          }}
          className="pagenation">{allStrings.next}</Button>
  
          <Button 
           onClick={()=>{
            if(this.state.page!==this.state.pages){
              this.getUsers(this.state.pages)
              this.setState({page:this.state.pages})
            }
          }}
          className="pagenation">{allStrings.last}</Button>
  
      </CardActions>
    )
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('notifS')) return <Redirect to='/Home' />
  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#1D9598'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>list_alt</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form062" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.usersCount}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.usersCount} type="number" min={0} id="form062" name="cost" className="form-control" disabled validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.title}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.title} type="text" id="form645" name="title" className="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.subjectId}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.subjectId} type="text" id="form645" name="subjectId" className="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                      <MDBCol md="12">
                                        <div className="md-form">
                                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.description}</label>
                                          <br></br>
                                          <textarea disabled={this.state.actionType==="VIEW"?true:false} name="description"  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate  defaultValue={this.state.description} />
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
          {this.state.actionType==="VIEW"&&
            <MDBRow>
                <MDBCol md="12">
                    <div className="infoTable">
                        <div className="topInfoTable" style={{ background: "#1D9598" }}>
                            <h4>
                            <span className="material-icons" style={{color:'white',fontSize:23}}>group</span>{ allStrings.users}
                            </h4>
                        </div>
                        <div className="tableDate">
                        <div className="table-responsive">
                            <table className="table table-striped">
                            <thead>
                              {this.props.isRTL?
                                <tr style={{textAlign:'center'}}>
                                    <th>{allStrings.more}</th>
                                    <th>{allStrings.email}</th>
                                    <th>{allStrings.phone}</th>
                                    <th>{allStrings.name}</th>
                                    <th scope="col">#</th>
                                </tr>
                                :
                                <tr style={{textAlign:'center'}}>
                                      <th scope="col">#</th>
                                      <th>{allStrings.name}</th>
                                      <th>{allStrings.email}</th>
                                      <th>{allStrings.phone}</th>
                                      <th>{allStrings.more}</th>
                                  </tr>
                              }
                                
                            </thead>
                            <tbody>
                            {this.state.loading?
                                <tr style={{height: '200px'}}>
                                    <th colSpan={"5"} className="noData">
                                    <Skeleton height={400}/> 
                                    </th>
                                </tr>
                                :
                                this.state.users.length > 0 ?
                                this.state.users.map(val =>(
                                  
                                  this.props.isRTL?
                                  <tr  style={{textAlign:'center'}}>
                                     <td
                                      onClick={()=>{this.props.history.push('/userInfo',{data:val}) }} 
                                      >
                                      <span style={{margin: "4px",cursor:'pointer'}}>
                                          <Tooltip title={allStrings.info} placement="top">
                                          <Info style={{color:'#33d633'}}/>
                                          </Tooltip>
                                      </span>
                                      </td>
                                      <td>{val.email?val.email:""}</td>
                                      <td>{val.phone?val.phone:""}</td>
                                      <td>{val.fullname}</td>
                                      <td >{val.id}</td>
                                  </tr>
                                  :
                                  <tr  style={{textAlign:'center'}}>
                                      <td >{val.id}</td>
                                      <td>{val.fullname}</td>
                                      <td>{val.email?val.email:""}</td>
                                      <td>{val.phone?val.phone:""}</td>
                                      <td
                                      onClick={()=>{this.props.history.push('/userInfo',{data:val}) }} 
                                      >
                                      <span style={{margin: "4px",cursor:'pointer'}}>
                                          <Tooltip title={allStrings.info} placement="top">
                                          <Info style={{color:'#33d633'}}/>
                                          </Tooltip>
                                      </span>
                                      </td>
                                  </tr>
                               
                                 ))
               
                                 :
                                 <tr style={{height: '200px'}}>
                                     <th colSpan="5" className="noData">
                                     <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                     </th>
                                 </tr>
                               }
                              
                               
                                
                            </tbody>
                            </table>
                            {this.state.users.length > 0 &&
                                <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                    {this.paginationButtons()}
                                </div>
                            }
                        </div>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
          }
            
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(notifInfo)
  );
