import React from 'react';
import './add bank.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message } from "antd";
import { UploadOutlined } from '@ant-design/icons';


class Addbank extends React.Component {
    state = {
        name_en:'',
        name_ar:'',
        link:'',
        img: null,
        imgType:'data',
        imgName:'',
        
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    add = () => {
        const {name_en,name_ar,img,imgType,link} = this.state
            if(hasValue(name_en)&&hasValue(name_ar) && hasValue(link)&& img !=null){
                let l = message.loading('Wait..', 2.5)
                let data = new FormData()
                data.append('name_en',name_en)
                data.append('name_ar',name_ar)
                data.append('link',link)
                if(img != null && imgType === "data"){
                    data.append('img',img)
                }
                console.log(Array.from(data))
                axios.post(`${BASE_END_POINT}banks`,data,{
                headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':`Bearer ${this.props.currentUser.token}`,
                'Accept-Language':this.props.isRTL?'ar':'en'
                },
                })
                .then(response=>{
                console.log("bank UPDATED  ",response.data)
                l.then(() => message.success(allStrings.done, 2.5) )
                    this.props.history.goBack() 
                })
                .catch(error=>{
                    console.log(error.response)
                    l.then(() => message.error(error.response.data.errors[0].msg, 2.5))
                })
        }
    }

   

    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.add()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    fileChangedHandler = event => {
      this.setState({
        img: event.target.files[0],
        imgName: event.target.files[0].name,
        imgType:"data"
      })
    }
    removeFile = () => {
      this.setState({
          img:null,
          imgName:'',
          imgType:"url"
      });
    }
  render(){
    console.log("type",this.state.type)
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('BANKS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#1D9598'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>account_balance</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{allStrings.AddBank}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.link}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.link} type="text" id="form645" name="link" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="fileUpload2"> 
                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.img&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img alt="" src={URL.createObjectURL(this.state.img)}  className="previewImg2"/>
                                                
                                                <span className="imgName">{this.state.imgName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                            
                                        </MDBCol>
                                    </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn
                                        style={{background:'#1D9598',color:'#fff'}}
                                        rounded
                                        color="#1D9598"
                                        type="submit"
                                        className="btn-block z-depth-1a"
                                        >
                                        <MDBIcon icon="plus" className="mr-2" />
                                            {allStrings.add}          
                                        </MDBBtn>
                                       
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(Addbank)
  );