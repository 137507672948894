
import React from 'react';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import './header.css'
import { MDBIcon} from "mdbreact";
import Avatar from '@material-ui/core/Avatar';
import { SearchOutlined,DownOutlined  } from '@ant-design/icons';
import { Input} from 'antd';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,MDBBadge/*,MDBBtn*/ } from "mdbreact";
import {hasValue} from '../../validations/validations'
import {message} from 'antd';
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import {getUnseenMessagesCount} from '../../redux/actions/ChatAction'
import {getNotifications,getUnreadNotificationsCount} from '../../redux/actions/NotificationAction'
import 'antd/dist/antd.css';


class Header extends React.Component {
  constructor(props){
    super(props)
    console.log("isr   ",this.props.isRTL)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }

  state ={
    isLogin:true,
    query:{},
    value:''
    
  }

  componentDidMount(){
    this.props.getNotifications(1,true,this.props.currentUser.token)
    this.props.getUnreadNotificationsCount(this.props.currentUser.token)
  }
 
  readNotification = (notID) => {
    console.log(notID)
    axios.put(`${BASE_END_POINT}notif/${notID}/read`,{}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    }).then(Response=>{
        console.log("notification is red")
        console.log(Response)
    }).catch(error=>{
        console.log(error.response)
    })
  }

  


  render(){
    console.log(this.state.value)
  return (
    <div  style={{width:'100%',background:'#fff'}}>
<nav className="navbar navbar-expand-lg navbar-light " style={{background:'transparent',color:'#fff'}}>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto ">
      <li></li>
    <li className="nav-item active">
        <Input 
        defaultValue={this.state.value}
        allowClear
        
         onKeyPress={(e)=>{
          if (e.key === 'Enter') {
            if(hasValue(""+e.target.value)){
              console.log("ENTER   ",e.target.value)
              const data = {
                ...this.state.query,
               search:e.target.value,
               index:-1,
               fromHome:true,
              }
              this.setState({query:data,value:''})
              this.props.history.push("/Search",{data:data})
            }else{
              message.warning(allStrings.noValue);
              console.log("ENTER NO VALUE   ",e.target.value)
            }
          }
         }}  
         placeholder={allStrings.SearchAbout}
        size="large"  prefix={<SearchOutlined />} />
     
    </li>
    <li></li>
    </ul>
    <ul className="navbar-nav mr-auto rightUl">
    <li>
    
    </li>
    {this.props.currentUser&&
    <li className="nav-item hasProfile">
        <MDBDropdown>
          <MDBDropdownToggle color={'transparent'} caret className={'customColor-text'} >
           
          <Avatar alt="Remy Sharp" src={this.props.currentUser?this.props.currentUser.user.img:require('../../assets/images/profileicon.jpg')} />
          <span className="headerUsername" style={{float: 'left',padding: '11px', minWidth: '50px',textAlign:'right'}}>{this.props.currentUser?this.props.currentUser.user.fullname :" "} <DownOutlined /></span>
          </MDBDropdownToggle>
          <MDBDropdownMenu basic>
          <MDBDropdownItem style={{fontSize: '14px',color:' rgb(82, 86, 89)'}}
          onClick={()=>{this.props.history.push('/userInfo',{data:this.props.currentUser.user})} }>
          {allStrings.Profile}
          
          </MDBDropdownItem>
            <MDBDropdownItem onClick={()=>{
              this.props.ChangeLanguage(true)
              allStrings.setLanguage('ar')
              localStorage.setItem("@LANG",'ar')
             
    
            }} > عربى</MDBDropdownItem>
            <MDBDropdownItem onClick={()=>{
              this.props.ChangeLanguage(false)
              allStrings.setLanguage('en')
              localStorage.setItem("@LANG",'en')
              
    
             
            }} > English</MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={()=>{
              localStorage.removeItem('@USERSAAEI')
              this.props.history.replace('/Login')
              this.props.setUser(null)
            }}> <img alt="" style={{        
              width: '15px',
              marginRight: '5px'
            }} src="https://res.cloudinary.com/dcsy8vdyz/image/upload/v1590391199/golkfoust6bcelmypcjo.svg"></img>{allStrings.logout}</MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
    </li>
    }
    <li className="nav-item nav-item-icon notifs">
    {this.props.currentUser&&
    <MDBDropdown>
      <MDBDropdownToggle color={'transparent'} caret className={'balck-text'}>
        
            <MDBIcon icon="bell" className={'customColor-text'} /><MDBBadge color="danger" className="ml-2">{this.props.unreadNotifCount}</MDBBadge>
              
      </MDBDropdownToggle>
      <MDBDropdownMenu basic flip>
        {this.props.notifications.map(noti=>(
      <MDBDropdownItem key={noti.id} onClick={()=>{
          if(!noti.read){
            this.readNotification(noti.id)
            this.props.getUnreadNotificationsCount(this.props.currentUser.token)
            
          }
      }} style={{fontSize: '14px',color:' rgb(82, 86, 89)'}}>
        <div className="notif" style={{
          borderRadius:'8px',
          background:!noti.read?'white':'white',
          border:!noti.read?'1.5px solid #1D9598':'1.5px solid #dedede'
          }}>
          <div className="personPic">
          <Avatar alt="Remy Sharp" src={noti.img?noti.img:require('../../assets/images/profileicon.jpg')} />
          </div>
          <div className="notifData">
            <p style={{fontWeight:'400'}}>{this.props.isRTL?noti.title:noti.title} <span style={{color:"#999",float:'right',fontWeight:'300',fontSize:'9px'}}>{""+new Date(noti.createdAt).toLocaleDateString()}</span></p>
            <p style={{color:"#999"}}>
              {this.props.isRTL?noti.description:noti.description}
            </p>
          </div>
          
        </div>

        </MDBDropdownItem>
       ))}
       <p style={{
          textAlign: 'center',
          lineHeight: 'initial',
          paddingTop: '10px',
          marginBottom: '0px',
          color: 'blue'
       }}>more</p>
      </MDBDropdownMenu>
    </MDBDropdown>
    }
    </li>
    <li></li>
    </ul>
  </div>
</nav>

  
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  unseenMsgCount: state.chat.unseenMsgCount,
  notifications: state.notif.notifications,
  notifPage: state.notif.notifPage,
  notifPages: state.notif.notifPages,
  unreadNotifCount: state.notif.unreadNotifCount
})

const mapDispatchToProps = {
  ChangeLanguage,
  setUser,
  getUnseenMessagesCount,
  getUnreadNotificationsCount,
  getNotifications,
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Header)
);
