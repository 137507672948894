import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './deal.css'
import {message,TreeSelect,DatePicker,ConfigProvider} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
const { TreeNode } = TreeSelect;
const { RangePicker } = DatePicker;
let headers = [
  { label: "#", key: "id" },
  { label: "العميل", key: "user" },
  { label: "العقار", key: "advertisement" },
  { label: "الحاله", key: "status" },
  { label: "النوع", key: "type" },
  { label: "التاريخ", key: "createdAt" },
];
 
let csvData = [
  
];
class Deal extends React.Component {
  
  state={
    deal:[],
    deal2:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    search:null,
    status:null,
    type:null,
    showFilter:false,
    startDate:null,
    endDate:null,
    csvData:[],
    count:0,
    isReady:false,
    buildCsv:false
  }

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    this.getDeals(1,false)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  delete = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}deals/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
       'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("deal DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getDeals(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }
  getDeals = (page,refresh) =>{
    this.setState({loading:true})
    let {status,startDate,endDate,search,type} = this.state
    let url = `${BASE_END_POINT}deals?page=${page}&all=true`
    if(refresh !== true ){
      let keysToFilter = {status,startDate,endDate,search,type}
      Object.keys(keysToFilter).forEach(key => {
        if (keysToFilter[key] !== null) {
          url += `&${key}=${keysToFilter[key]}`;
        }
      });
    }
    
    axios.get(url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      },
     })
    .then(response=>{
       console.log("deal   ",response.data)
      let data = response.data.data.map((item,index)=>{
      return  [
        item.id,
        item.user?item.user.fullname?item.user.fullname.substr(0,15):"":"",
        item.advertisement?item.advertisement.title:"",
        item.status==="BOOKED"?{type:'tag',value:allStrings.booked}:item.status==="ACTIVE"?{type:'tag',value:allStrings.active}:{type:'tag',value:allStrings.completed},
        item.type==="ONLINE"?{type:'tag',value:allStrings.onApp}:{type:'tag',value:allStrings.outApp},
        item.createdAt?this.props.isRTL?moment(item.createdAt).locale('ar').format('ll'):moment(item.createdAt).locale('en').format('ll'):"",
        "action"
      ]
      })  
      this.setState({
        loading:false,
        refresh:false,
        deal:response.data.data,
        deal2:data,
        page:response.data.page,
        count:response.data.totalCount,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getDealsWithoutPagenation = (refresh) =>{
    let {status,startDate,endDate,search,type} = this.state
    let url = `${BASE_END_POINT}deals/withoutPagenation/get`
    if(refresh !== true ){
      let keysToFilter = {status,startDate,endDate,search,type}
      Object.keys(keysToFilter).forEach(key => {
        if (keysToFilter[key] !== null) {
          url += `&${key}=${keysToFilter[key]}`;
        }
      });
    }
    
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      }
    })
    .then(response=>{
      
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{user:item.user?item.user.fullname.substr(0,15):""},
            ...{advertisement:item.advertisement?item.advertisement.title:""},
            ...{status:item.status ==="BOOKED"?allStrings.booked:item.status ==="ACTIVE"?allStrings.active:allStrings.completed},
            ...{type:item.type==="ONLINE"?allStrings.onApp:allStrings.outApp},        
            ...{createdAt:item.createdAt?this.props.isRTL?moment(item.createdAt).locale('ar').format('ll'):moment(item.createdAt).locale('en').format('ll'):""}
          };
        });
        this.setState({csvData:csvData,isReady:true})      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }
  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getDeals(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getDeals(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getDeals(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#659FF1'} !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getDeals(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getDeals(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )
  onChange = (date, dateString) =>{
    if(this.props.isRTL){
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD"),
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
    }else{
      this.setState({to:moment(dateString).locale('en').format("YYYY/MM/DD") })
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD") ,
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
      console.log(moment(dateString[0]).locale('en').format("YYYY-MM-DD") )

    }
    console.log( dateString);
  }
  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="addDeal">
        <Fab
          style={{background:'#1D9598',float:'left',margin:'50px 30px',width: '160px',borderRadius: '50px'}}
          letiant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addDeal}
        </Fab>
      </NavLink>
    </div>
  )

  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CONTACT-REQUEST')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          <Table
            settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{zIndex: '1',position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.getDeals(1,true);this.setState({buildCsv:false})}} 
                class="material-icons">
                  replay
                </span>
                {!this.state.buildCsv && 
                  <img crossorigin="anonymous"alt="" onClick={() => {this.setState({buildCsv:true});this.getDealsWithoutPagenation()}}
                  style={{padding: '0px',position: 'relative',top: '-6px',marginLeft:'5px',cursor: 'pointer'}} 
                  src={require('../../assets/images/buildCsv.png')} width="26"></img>
                }{!this.state.isReady && this.state.buildCsv&&
                  <img crossorigin="anonymous" alt="" 
                  style={{padding: '0px',position: 'relative',top: '-5px',marginLeft:'-15px'}} 
                  src={require('../../assets/images/loading.gif')} width="70"></img>
                }{this.state.isReady && this.state.buildCsv&&
                <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"deals.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
                }
              </div>
            }
            filter ={
              <div>
                <div className={this.state.showFilter?'showClass':'hideClass'}>
                  
                  <MDBRow>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                      <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                      <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                        <RangePicker format={"YYYY-MM-DD"} placeholder={[allStrings.startDate,allStrings.endDate]} onChange={this.onChange} />
                      </ConfigProvider>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                        <TreeSelect virtual={false}
                            showSearch={false}
                            value={this.state.status}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.status}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({status:value});
                            }} 
                        
                        >
                            <TreeNode value="BOOKED" title={allStrings.booked} key="BOOKED" />
                            <TreeNode value="ACTIVE" title={allStrings.active} key="ACTIVE" />
                            <TreeNode value="COMPLETED" title={allStrings.completed} key="COMPLETED" />
                            
                        </TreeSelect>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                        <TreeSelect virtual={false}
                            showSearch={false}
                            value={this.state.type}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.type}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({type:value});
                            }} 
                        
                        >
                            <TreeNode value="ONLINE" title={allStrings.onApp} key="ONLINE" />
                            <TreeNode value="OFFLINE" title={allStrings.outside} key="OFFLINE" />
                            
                        </TreeSelect>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="12" lg="12" md="12" sm="12">
                      <div class="md-form">
                      <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.Search}</label>
                      <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',fontWeight:'300'}} type="text" id="form605"  class="form-control"
                        placeholder={allStrings.Search}
                        onChange={(e)=>{
                          this.setState({search:e.target.value})
                        }}  
                      />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <Button style={{background: '#1D9598',color: '#fff',width: '100%',margin: '32px auto'}} 
                    onClick={()=>{this.getDeals(1);this.setState({buildCsv:false})}}><SearchOutlined /> {allStrings.Search}</Button>

                </div>
              </div>
            }
            title={allStrings.Deals}
            enableEdit={false}
            noDelete={false}
            icon='content_paste'
            data={this.state.deal}
            data2={this.state.deal2}
            tableColumns={["#",allStrings.client,allStrings.adveristment,allStrings.status,allStrings.type,allStrings.createdAt,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.delete(id)}}
            path='/dealInfo'
            actionIndex={5}
            dataCount={this.state.count}
            />
            {this.paginationButtons()}
            {this.addNewRecordButton()}
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Deal)
);

