import React from 'react';
import './ads info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import allStrings from "../../assets/strings";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from 'react-loading-skeleton';
import { message,Image,Spin,Empty} from "antd";
import moment from "moment";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

class adsInfo extends React.Component {
    state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        size:this.props.location.state.data.size?this.props.location.state.data.size:"",
        status:this.props.location.state.data.status?this.props.location.state.data.status:"",
        category:this.props.location.state.data.category?this.props.location.state.data.category.categoryName:null,
        categoryType: this.props.location.state.data.category?this.props.location.state.data.category.type:null,
        subCategory:this.props.location.state.data.subCategory?this.props.location.state.data.subCategory.categoryName:null,
        area:this.props.location.state.data.area?this.props.location.state.data.area.areaName:null,
        city:this.props.location.state.data.city?this.props.location.state.data.city.cityName:null,
        inMarket:false,
        commissionCost:0,
        createdAt:this.props.location.state?this.props.location.state.data.createdAt?this.props.location.state.data.createdAt:null:null,
        features:[],
        properties:[],
        rate: this.props.location.state.data.rate?this.props.location.state.data.rate:0,
        viewsCount:this.props.location.state.data.viewsCount?this.props.location.state.data.viewsCount:0,
        id:this.props.location.state.data.id,
        location:this.props.location.state.data.location?this.props.location.state.data.location:"",
        contractType:this.props.location.state.data.contractType?this.props.location.state.data.contractType:"",
        price:this.props.location.state.data.price?this.props.location.state.data.price:0,
        priceType:this.props.location.state.data.priceType?this.props.location.state.data.priceType:"NORMAL",
        rentType:this.props.location.state.data.rentType?this.props.location.state.data.rentType:"MONTHLY",
        owner:this.props.location.state.data.owner?this.props.location.state.data.owner:[],
        paymentPlan:null,
        company:"",

        allImgs:[],
        lands:[],
        units:[],
        villas:[],
        buildings:[],
        bestFeature_en:"",
        bestFeature_ar:"",
        address_ar:"",
        address_en:"",
        enableInstallment:"",
        commissionNumber: "",
        advertiserClass: "",
        deposit:"",
        hasDeposit: "",
        region:null,
        advertisementImgs:[],
        description_en:"",
        description_ar:"",
        title_en:"",
        title_ar:"",
        available:"",
        seoDescription_en:"",
        seoDescription_ar:"",
        seoTitle_en:"",
        seoTitle_ar:"",
        imgs: null,
        imgsType:'data',
        subCategories:[],
        categories:[],
        areas:[],
        regions:[],
        cities:[],
        marketingAdInfo:[],
        pages:1,
        page:1,
        isInRedf:false,
        seoTags:[],
        copyColor:'#1D9598',
        modal:false,
        modal2:false,
        modal3:false
    };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        this.getAdsData(this.props.location.state.data.id)
        this.getAdMarketing(1,this.props.location.state.data.id)

        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    
    getAdsData = (id) => {
      this.setState({loading:true})
      axios.get(`${BASE_END_POINT}/ads/${id}`,{
        headers: {
        'Content-Type': 'applications/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
        }})
      .then(response=>{
          console.log('theAds  ',response.data)
          this.setState({
            loading: false,
            size:response.data.data.size,
            status:response.data.data.status,
            area:response.data.data.area?response.data.data.area.areaName:null,
            city:response.data.data.city?response.data.data.city.cityName:null,
            id:response.data.data.id,
            location:response.data.data.location?response.data.data.location:"",
            contractType:response.data.data.contractType,
            price:response.data.data.price,
            priceType:response.data.data.priceType,
            rentType:response.data.data.rentType?response.data.data.rentType:"MONTHLY",
            owner:response.data.data.owner?response.data.data.owner:[],
            viewsCount: response.data.data.viewsCount?response.data.data.viewsCount:0,
            allImgs: response.data.data.imgs?response.data.data.imgs:[],
            inMarket:response.data.data.inMarket?response.data.data.inMarket:false,
            commissionCost:response.data.data.commissionCost?response.data.data.commissionCost:0,
            createdAt: response.data.data.createdAt?response.data.data.createdAt:null,
            available: response.data.data.available?response.data.data.available:false,
            bestFeature_en:response.data.data.bestFeature_en?response.data.data.bestFeature_en:"",
            bestFeature_ar:response.data.data.bestFeature_ar?response.data.data.bestFeature_ar:"",
            address_ar:response.data.data.address_ar?response.data.data.address_ar:"",
            address_en:response.data.data.address_en?response.data.data.address_en:"",
            enableInstallment:response.data.data.enableInstallment?response.data.data.enableInstallment:false,

            commissionNumber: response.data.data.commissionNumber?response.data.data.commissionNumber:"",
            advertiserClass: response.data.data.advertiserClass?response.data.data.advertiserClass:"",
            deposit:response.data.data.deposit?response.data.data.deposit:"",
            depositPaidUser:response.data.data.depositPaidUser?response.data.data.depositPaidUser:null,
            hasDeposit: response.data.data.hasDeposit?response.data.data.hasDeposit:"",
            region:response.data.data.region?response.data.data.region.id:null,
            category:response.data.data.category?response.data.data.category.categoryName:null,
            categoryType:response.data.data.category?response.data.data.category.type:null,
            subCategory:response.data.data.subCategory?response.data.data.subCategory.categoryName:null,
            description_en:response.data.data.description_en?response.data.data.description_en:"",
            description_ar:response.data.data.description_ar?response.data.data.description_ar:"",
            title_en:response.data.data.title_en?response.data.data.title_en:"",
            title_ar:response.data.data.title_ar?response.data.data.title_ar:"",
            rate: response.data.data.rate?response.data.data.rate:0,
            features: response.data.data.features?response.data.data.features:[],
            properties: response.data.data.properties?response.data.data.properties:[],
            lands: response.data.data.lands?response.data.data.lands:[],
            villas:response.data.data.villas?response.data.data.villas:[],
            buildings: response.data.data.buildings?response.data.data.buildings:[],
            units: response.data.data.units?response.data.data.units:[],
            seoDescription_en: response.data.data.seoDescription_en?response.data.data.seoDescription_en:"",
            seoDescription_ar:response.data.data.seoDescription_ar?response.data.data.seoDescription_ar:"",
            seoTitle_en: response.data.data.seoTitle_en?response.data.data.seoTitle_en:"",
            seoTitle_ar: response.data.data.seoTitle_ar?response.data.data.seoTitle_ar:"",
            seoTags: response.data.data.seoTags?response.data.data.seoTags:[],
            isInRedf:response.data.data.isInRedf,
            paymentPlan: response.data.data.paymentPlan,
            company: response.data.data.company?response.data.data.company.name:'ساعى'
          })
          if(response.data.data.imgs.length > 0){
            let i = response.data.data.imgs.map(img=>{
              return{
               original:img,
               thumbnail:img,
              }
          })
          this.setState({advertisementImgs:i})
          window.scrollTo(0,0)
          }
          
      })
      .catch(error=>{
          console.log('add ERROR  ',error)
          console.log('add ERROR  ',error.respose)
      })
    }
    getAdMarketing = (page,id) => {
      axios.get(`${BASE_END_POINT}marketerAds?advertisement=${id}&page=${page}&limit=${10}`,{
        headers: {
        'Content-Type': 'applications/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
        }})
      .then(response=>{
          console.log('marketingAdInfo  ',response.data)
          this.setState({
            marketingAdInfo:response.data.data,
            pages:response.data.pageCount,
            page:response.data.page,
            loading:false,
          })
      })
      .catch(error=>{
          console.log('marketingAdInfo ERROR  ',error)
          console.log('marketingAdInfo ERROR  ',error.respose)
      })
    }
    componentDidMount(){
      window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}ads/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("ads DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    sendToRedf = () => {
      let l = message.loading('Wait..', 2.5)
      axios.post(`${BASE_END_POINT}ads/${this.props.location.state.data.id}/addRedfAdvertisement`,{},{
        headers: {
        'Content-Type': 'applications/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      }})
      .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
  }
    setNewData = (id) =>{
        console.log('setNewData  ',id)
        this.getAdsData(id)
        this.getCategory()
        this.getAdMarketing(1,id)
    }
    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    available = (available) => {
      let uri ='';
      if(available){
       uri = `${BASE_END_POINT}ads/${this.state.id}/available`
      }else{
       uri = `${BASE_END_POINT}ads/${this.state.id}/dis-available`
      }
     let l = message
      .loading('Wait..', 2.5)
      axios.put(uri,{},{
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${this.props.currentUser.token}`
       },
     })
      .then(response=>{
          l.then(() => message.success(allStrings.done, 2.5))
          this.props.history.goBack()
      })
      .catch(error=>{
       console.log('Error   ',error.response)
       l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    marketAdvertisement = (inMarket) => {
      let uri ='';
      if(inMarket){
       uri = `${BASE_END_POINT}ads/${this.state.id}/addInMarket`
      }else{
       uri = `${BASE_END_POINT}ads/${this.state.id}/removeFromMarket`
      }
     let l = message
      .loading('Wait..', 2.5)
      axios.put(uri,{commissionCost:this.state.commissionCost},{
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${this.props.currentUser.token}`
       },
     })
      .then(response=>{
           if(inMarket){
               l.then(() => message.success(allStrings.done, 2.5))
               this.toggle2()
               this.setState({inMarket:true})
           }else{
              l.then(() => message.success(allStrings.done, 2.5))
              this.setState({inMarket:false})
           }
      })
      .catch(error=>{
       console.log('Error   ',error.response)
       l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    imageSection = () =>(
      <MDBCol xl="4" lg="5" md="12" sm="12">
      <div className="ownerInfoCard">
          <div style={{height: '435px',overflow: 'hidden'}}>
            <div className="ownerImg">
            <img alt="" src={this.state.owner.img?this.state.owner.img:require('../../assets/images/profileicon.jpg')}/>
            </div>
            <div className="ownerInfo">
                <p className="pName">{this.state.owner.fullname?this.state.owner.fullname:""}</p>
                <p className="pInfo">
                  <span class="material-icons infoIcon">location_city</span>
                  {this.state.owner.city?this.state.owner.city.cityName:allStrings.notExist}
                </p>
                <p className="pInfo">
                  <span class="material-icons infoIcon">call</span>
                  {this.state.owner.phone?this.state.owner.phone:allStrings.notExist}
                </p>
                <p className="pInfo">
                  <span class="material-icons infoIcon">email</span>
                  {this.state.owner.email?this.state.owner.email:allStrings.notExist}
                </p>
                <p className="pInfo">
                  <span class="material-icons infoIcon">person_pin</span>
                  {this.state.owner.type === "ADMIN"?allStrings.admin:this.state.owner.type==="OFFICE"?allStrings.office:allStrings.client}
                </p>
            </div>
            <div className="ownerInfoFooter">
            <Button style={{background: '#1D9598',color: '#fff',width: '90%',margin: 'auto',display: 'block'}} 
            onClick={()=>{this.props.history.push('/userInfo',{data:this.state.owner}) }}>{allStrings.viewInfo}</Button>

            </div>
          </div>
      </div>
      <div className="infoCard" style={{marginTop:'10px'}}>
        <div className="StoreSlider">
          <div style={{height: '280px',overflow: 'hidden'}}>
            <Image.PreviewGroup >
              {this.state.advertisementImgs.map((val,i)=>
                <Image placeholder={true} width="100%" height="100%" style={{display: i===0?"block":"none",borderRadius:'6px'}}   src={val.original} />
              )}
            </Image.PreviewGroup>
          </div>
        </div>
      </div>
    </MDBCol>
    
    ) 
    buttonsSection = () =>{
      return(
        <div className="btnOption">
        <MDBRow>
          <MDBCol md="4">
                <MDBBtn
                  color="red"
                  rounded
                  style={{ borderRadius: "2px",background:'red' }}
                  className="btn-block z-depth-1a"
                  onClick={()=>{
                      this.delete()
                  }}
                >
                  <MDBIcon icon="trash-alt" className="mr-2" />
                  {allStrings.remove}
                </MDBBtn>
          </MDBCol>
          {this.state.available?
          <MDBCol md="4">
            <MDBBtn
              rounded
              color="warning"
              style={{ borderRadius: "2px" }}
              className="btn-block z-depth-1a"
              onClick={()=>{
                this.available(false)
              }}
            >
              <MDBIcon icon="ban" className="mr-2" />
              {allStrings.notAvailable}
            </MDBBtn>
          </MDBCol>
          :
          <MDBCol md="4">
            <MDBBtn
              rounded
              color="info"
              style={{ borderRadius: "2px" }}
              className="btn-block z-depth-1a"
              onClick={()=>{
                this.available(true)
              }}
            >
              <MDBIcon icon="check-circle" className="mr-2" />
              {allStrings.available}
            </MDBBtn>
          </MDBCol>
          }
          {this.state.inMarket?
          <MDBCol md="4">
            <MDBBtn
              rounded
              color="warning"
              style={{ borderRadius: "2px" }}
              className="btn-block z-depth-1a"
              onClick={()=>{
                this.marketAdvertisement(false)
              }}
            >
              <MDBIcon icon="ban" className="mr-2" />
              {allStrings.removeFromMarket}
            </MDBBtn>
          </MDBCol>
          
          : 
          <MDBCol md="4">
            <MDBBtn
              rounded
              color="info"
              style={{ borderRadius: "2px" }}
              className="btn-block z-depth-1a"
              onClick={()=>{this.toggle2()}} 
            >
              <MDBIcon icon="check-circle" className="mr-2" />
              {allStrings.addInMarket}
            </MDBBtn>
          </MDBCol>
          }
          
        </MDBRow>
      </div>
      )
    }
    copyText = () => {
      this.setState({copyColor:'green'})
      this.refs.input.select();
      document.execCommand('copy');
      return false;
    }
    submitHandlerAddInMarket = (event) => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.marketAdvertisement(true)
    };
    toggle2 = () => {
      this.setState({
        modal2: !this.state.modal2,
      });
    }
    modal2 = () =>(
      <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="lg">
        <MDBModalHeader toggle={this.toggle2} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerAddInMarket}
                    noValidate
                  >
                    <div className="grey-text">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="md-form">
                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.commission}</label>
                          <br></br>
                          <input  name="commissionCost" onChange={this.changeHandler} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="number" min={0} id="form920" className="form-control"  validate required  />
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#1D9598' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon icon="plus" className="mr-2" />
                        {allStrings.add}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    toggle = () => {
      this.setState({
        modal: !this.state.modal,
      });
    }
    modal = () =>(
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerAddSeoDesc}
                    noValidate
                  >
                    <div className="grey-text">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="md-form">
                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishDescription}</label>
                          <br></br>
                          <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } name="seoDescription_en" value={this.state.seoDescription_en} onChange={this.changeHandler} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control"  validate required   />
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="md-form">
                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicDescription}</label>
                          <br></br>
                          <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="seoDescription_ar" value={this.state.seoDescription_ar} onChange={this.changeHandler} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control"  validate required  />
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="md-form">
                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishTitle}</label>
                          <br></br>
                          <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } name="seoTitle_en" value={this.state.seoTitle_en} onChange={this.changeHandler} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control"  validate required   />
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="md-form">
                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicTitle}</label>
                          <br></br>
                          <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="seoTitle_ar" value={this.state.seoTitle_ar} onChange={this.changeHandler} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control"  validate required  />
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="md-form">
                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.seoTags}</label>
                          <br></br>
                          <TagsInput value={this.state.seoTags} onChange={this.handleTagsChange} />
                        </div>
                      </MDBCol>
                    </MDBRow>
                    
                    
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#1D9598' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon icon="plus" className="mr-2" />
                        {allStrings.add}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    addSeoDesc = () => {
      const {seoDescription_en,seoDescription_ar,seoTitle_ar,seoTitle_en,seoTags} = this.state
      if(hasValue(seoDescription_en)&&hasValue(seoDescription_ar)){
          let l = message.loading('Wait..', 2.5)
          const data={
              'seoDescription_en':seoDescription_en,
              'seoDescription_ar':seoDescription_ar,
              'seoTitle_en':seoTitle_en,
              'seoTitle_ar':seoTitle_ar,
              'seoTags':seoTags
          }
          console.log(data)
          axios.put(`${BASE_END_POINT}ads/${this.state.id}/addSeoDesc`,JSON.stringify(data),{
              headers: {
              'Content-Type': 'application/json',
              'Authorization':`Bearer ${this.props.currentUser.token}`,
              'Accept-Language':this.props.isRTL?'ar':'en'
              },
          })
          .then(response=>{
              l.then(() => message.success(allStrings.done, 2.5) )
              this.toggle()
              this.props.history.goBack() 
          })
          .catch(error=>{
              console.log(error.response)
              l.then(() => message.error(allStrings.error, 2.5))
          })
      }
    }
    submitHandlerAddSeoDesc = (event) => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.addSeoDesc()
    };
    toggle3 = () => {
      this.setState({
        modal3: !this.state.modal3,
      });
    }
    modal3 = () =>(
      <MDBModal isOpen={this.state.modal3} toggle={this.toggle3} size="lg">
        <MDBModalHeader toggle={this.toggle3} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerAddContentDesc}
                    noValidate
                  >
                    <div className="grey-text">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="md-form">
                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishBestFeatures}</label>
                          <br></br>
                          <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') } name="bestFeature_en" value={this.state.bestFeature_en} onChange={this.changeHandler} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control"  validate required   />
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="md-form">
                          <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicBestFeatures}</label>
                          <br></br>
                          <textarea onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="bestFeature_ar" value={this.state.bestFeature_ar} onChange={this.changeHandler} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control"  validate required  />
                          <div class="valid-feedback">{allStrings.correct}</div>
                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#1D9598' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon icon="plus" className="mr-2" />
                        {allStrings.add}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    addContentDesc = () => {
      const {bestFeature_en,bestFeature_ar} = this.state
      if(hasValue(bestFeature_en)&&hasValue(bestFeature_ar)){
          let l = message.loading('Wait..', 2.5)
          const data={
              'bestFeature_en':bestFeature_en,
              'bestFeature_ar':bestFeature_ar
          }
          console.log(data)
          axios.put(`${BASE_END_POINT}ads/${this.state.id}/addContentDesc`,JSON.stringify(data),{
              headers: {
              'Content-Type': 'application/json',
              'Authorization':`Bearer ${this.props.currentUser.token}`,
              'Accept-Language':this.props.isRTL?'ar':'en'
              },
          })
          .then(response=>{
              l.then(() => message.success(allStrings.done, 2.5) )
              this.toggle3()
              this.props.history.goBack() 
          })
          .catch(error=>{
              console.log(error.response)
              l.then(() => message.error(allStrings.error, 2.5))
          })
      }
    }
    submitHandlerAddContentDesc = (event) => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.addContentDesc()
    };
    handleTagsChange = (tags) => {
      this.setState({seoTags:tags})
    }
    adMarkeingTable = () =>{
        return(
        <MDBRow>
        <MDBCol md="12">
            <div className="infoTable">
                <div className="topInfoTable" style={{ background: '#1D9598' }}>
                    <h4>
                    {allStrings.marketInfo}
                    </h4>
                </div>
                <div className="tableDate">
                    <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                          {this.props.isRTL?
                            <tr style={{textAlign:'center'}}>
                                <th>{allStrings.more}</th>
                                <th>{allStrings.commission}</th>
                                <th>{allStrings.status}</th>
                                <th>{allStrings.marketer}</th>
                                <th scope="col">#</th>
                            </tr>
                                :
                            <tr style={{textAlign:'center'}}>
                                <th scope="col">#</th>
                                <th>{allStrings.marketer}</th>
                                <th>{allStrings.status}</th>
                                <th>{allStrings.commission}</th>
                                <th>{allStrings.more}</th>
                            </tr>
                          }
                              
                        </thead>
                        <tbody>
                        {this.state.loading?
                            <tr style={{height: '200px'}}>
                                <th colSpan="6" className="noData">
                                <Skeleton height={400}/> 
                                </th>
                            </tr>
                            :
                            this.state.marketingAdInfo.length > 0 ?
                            this.state.marketingAdInfo.map(val =>(
                              this.props.isRTL?
                              <tr style={{textAlign:'center'}}>
                                  <td>
                                  <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push("marketingInfo",{data:val}) }} >
                                      <Tooltip title={allStrings.info} placement="top">
                                      <Info style={{color:'#33d633'}}/>
                                      </Tooltip>
                                  </span>
                                  </td>
                                  <td>{val.commission}</td>
                                  <td>{val.status ==="ON-PROGRESS"?allStrings.marketingOnProgress:val.status ==="WAITING-COMMISSION"?allStrings.waitingCommission:val.status ==="COMPLETED"?allStrings.marketingComplete:allStrings.marketingFailed}</td>
                                  <td>{val.user.fullname}</td>
                                  <td>{val.id}</td>
                              </tr>
                              :
                              <tr style={{textAlign:'center'}}>
                                  <td>{val.id}</td>
                                  <td>{val.user.fullname}</td>
                                  <td>{val.commission}</td>
                                  <td>{val.status ==="ON-PROGRESS"?allStrings.marketingOnProgress:val.status ==="WAITING-COMMISSION"?allStrings.waitingCommission:val.status ==="COMPLETED"?allStrings.marketingComplete:allStrings.marketingFailed}</td>
                                  <td>
                                  <span style={{margin: "4px",cursor:'pointer'}} onClick={()=>{this.props.history.push("marketingInfo",{data:val}) }} >
                                      <Tooltip title={allStrings.info} placement="top">
                                      <Info style={{color:'#33d633'}}/>
                                      </Tooltip>
                                  </span>
                                  </td>
                              </tr>
                                ))
            
                                :
                                <tr style={{height: '200px'}}>
                                    <th colSpan="6" className="noData">
                                    <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                    </th>
                                </tr>
                            }
                        </tbody>
                        </table>
                        {this.state.marketingAdInfo.length > 0 &&
                            <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                {this.paginationButtons()}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </MDBCol>
    </MDBRow>
        )
    }
    paginationButtons = () =>(
      <CardActions style={{display: 'inline-flex'}}>
          <Button 
          onClick={()=>{
            if(this.state.page!==1){
              this.getAdMarketing(1,this.state.id)
              this.setState({page:1})
            }
          }}
          className="pagenation" >{allStrings.first}</Button>
  
          <Button 
          onClick={()=>{
            if(this.state.page>1){
              this.getAdMarketing(this.state.page-1,this.state.id)
              this.setState({page:this.state.page-1})
            }
          }}
          className="pagenation">{allStrings.prev}</Button>
          
          <Pagination
          onChange={(event,page)=>{
          console.log("page   ",page)
          if(page!==this.state.page){
            this.getAdMarketing(page,this.state.id)
            this.setState({page:page})
          }
          
          }} 
          defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#1D9598'} !important`}} />
          
          <Button 
          onClick={()=>{
            if(this.state.page<this.state.pages){
              this.getAdMarketing(this.state.page+1,this.state.id)
              this.setState({page:this.state.page+1})
            }
          }}
          className="pagenation">{allStrings.next}</Button>
  
          <Button 
            onClick={()=>{
            if(this.state.page!==this.state.pages){
              this.getAdMarketing(this.state.pages,this.state.id)
              this.setState({page:this.state.pages})
            }
          }}
          className="pagenation">{allStrings.last}</Button>
  
      </CardActions>
    )
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('ADS')) return <Redirect to='/Home' />
    return (
      <div className="App">
        <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#1D9598'}}>
        {this.modal()}
        {this.modal2()}
        {this.modal3()}
        
          <MDBContainer> 
            <MDBRow>  
                <MDBCol xl={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                            <CardMedia
                            style={{height:"0px"}}
                            />
                            <CardHeader></CardHeader>
                            <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                <div className="topIcon" style={{background:'#1D9598'}}>
                                    <span class="material-icons" style={{color:'white',fontSize:23}}>featured_video</span>
                                </div>
                                <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                                marginLeft:'0',marginRight:'0'}}>
                                <p>{allStrings.theAdd}</p>
                                </div>
                            </div>
                            <div style={{width:'100%',display:'none'}}>
                              <Rater style={{float:this.props.isRTL? 'left':'right',marginRight:'20px',marginLeft:'20px'}} rating={this.state.rate} total={5} interactive={false}/>
                            </div>
                            <CardContent style={{minHeight:"390px",outline:'none'}}>
                            <MDBContainer>
                                  <span class="material-icons" 
                                    onClick={ this.copyText } 
                                    style={{cursor:'pointer',color:this.state.copyColor}}>
                                    content_copy
                                  </span>
                                  <input
                                      ref="input"
                                      type="text"
                                      defaultValue={"https://saaei.co/realty-management/view-realty-advertisement/" + this.state.id}
                                      style={{ position: 'fixed', top: '-1000px' }} />
                                <MDBRow>
                                    <MDBCol md="12">
                                    <form
                                    className="needs-validation"
                                    onSubmit={this.submitHandler}
                                    noValidate
                                    >
                                        
                                        <div className="grey-text">
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form635" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.status} type="text" id="form635"  class="form-control" />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form605"  class="form-control" />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form635" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.inMarket}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.inMarket?allStrings.yes:allStrings.no} type="text" id="form635"  class="form-control" />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.commission}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.commissionCost} type="text" id="form605"  class="form-control" />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form655" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishTitle}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.title_en} type="text" id="form655" name="title_en" class="form-control"/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicTitle}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.title_ar} type="text" id="form56" name="title_ar" class="form-control"/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form6005" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.available}</label>
                                                <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.available?allStrings.yes:allStrings.no} type="text" id="form6005"  class="form-control" />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form6095" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.contractType}</label>
                                                <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.contractType==="SALE"?allStrings.sale:allStrings.rent} type="text" id="form6095"  class="form-control" />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <div class="md-form">
                                                <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishDescription}</label>
                                                <br></br>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                <textarea disabled  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} value={this.state.description_en} type="text" id="form645" name="description_en" class="form-control"/>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <div class="md-form">
                                                <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicDescription}</label>
                                                <br></br>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                <textarea disabled style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} value={this.state.description_ar} type="text" id="form56" name="description_ar" class="form-control"/>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <div class="md-form">
                                                <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishSeoDescription}</label>
                                                <br></br>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                <textarea disabled  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} value={this.state.seoDescription_en !==""?this.state.seoDescription_en:allStrings.notExist} type="text" id="form645" name="description_en" class="form-control"/>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <div class="md-form">
                                                <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicSeoDescription}</label>
                                                <br></br>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                <textarea disabled style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} value={this.state.seoDescription_ar !==""?this.state.seoDescription_ar:allStrings.notExist} type="text" id="form56" name="description_ar" class="form-control"/>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <div class="md-form">
                                                <label htmlFor="form05" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishBestFeatures}</label>
                                                <br></br>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                <textarea disabled  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} value={this.state.bestFeature_en} type="text" id="form05" name="bestFeature_en" class="form-control"/>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <div class="md-form">
                                                <label htmlFor="form36" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicBestFeatures}</label>
                                                <br></br>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                <textarea disabled style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} value={this.state.bestFeature_ar} type="text" id="form36" name="bestFeature_ar" class="form-control"/>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <div class="md-form">
                                                <label htmlFor="form15" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishAddress}</label>
                                                <br></br>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                <textarea disabled  style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} value={this.state.address_en} type="text" id="form15" name="address_en" class="form-control"/>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <div class="md-form">
                                                <label htmlFor="form26" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicAddress}</label>
                                                <br></br>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                <textarea disabled style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} value={this.state.address_ar} type="text" id="form26" name="address_ar" class="form-control"/>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form562" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.size}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.size} min={0} type="number" id="form562" name="size" class="form-control"/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form569" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.price}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={1} value={this.state.price}  id="form569" name="price" class="form-control"/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form009" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.deposit}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" value={this.state.deposit?this.state.deposit:allStrings.no}  id="form009" name="deposit" class="form-control"/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form009" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.advertiserClass}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" value={this.state.advertiserClass==="OWNER"?allStrings.owner:allStrings.commissioner}  id="form009" name="advertiserClass" class="form-control"/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form119" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.commissionNumber}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" value={ this.state.commissionNumber?this.state.commissionNumber:allStrings.no}  id="form119" name="commissionNumber" class="form-control"/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form119" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.company}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" value={ this.state.company}  id="form119" name="company" class="form-control"/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol md="6">
                                              <div class="md-form">
                                              <label htmlFor="form402" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.subCategory}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.subCategory}  type="text" id="form402" name="subCategory" class="form-control" required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                              <div class="md-form">
                                              <label htmlFor="form409" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text"  value={this.state.category}  id="form409" name="category" class="form-control" required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol md="6">
                                              <div class="md-form">
                                              <label htmlFor="form402" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.area}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.area}  type="text" id="form402" name="area" class="form-control" required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                              <div class="md-form">
                                              <label htmlFor="form409" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.city}</label>
                                              <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text"  value={this.state.city}  id="form409" name="city" class="form-control" required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form6005" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.available}</label>
                                                <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.available?allStrings.yes:allStrings.no} type="text" id="form6005"  class="form-control" />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form6095" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.contractType}</label>
                                                <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.contractType==="SALE"?allStrings.sale:allStrings.rent} type="text" id="form6095"  class="form-control" />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                <label htmlFor="form6015" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                                                <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={moment(this.state.createdAt).format('MMMM Do YYYY, h:mm a')} type="text" id="form6015"  class="form-control" />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div class="md-form">
                                                    <label htmlFor="form6015" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.views}</label>
                                                    <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.viewsCount} type="text" id="form6015"  class="form-control" />
                                                    <div class="valid-feedback">{allStrings.correct}</div>
                                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        
                                        {this.state.depositPaidUser&&
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                            <MDBCol md="10" xs="10">
                                                <div class="md-form">
                                                <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.depositPaidUser}</label>
                                                <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.owner?this.state.owner.fullname:''} type="text" id="form80" class="form-control"  />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="2" xs="2">
                                          
                                            <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                                <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                                onClick={()=>{this.props.history.push('userInfo',{data:this.state.depositPaidUser}) }}>
                                                  remove_red_eye
                                                </span>
                                                <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                                  onClick={()=>{this.props.history.push('userInfo',{data:this.state.depositPaidUser}) }}>
                                                  {allStrings.show}
                                                </span> 
                                              </p>
                                            </MDBCol>
                                        </MDBRow>
                                        }
                                        {this.state.paymentPlan&&
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                            <MDBCol md="10" xs="10">
                                                <div class="md-form">
                                                <label htmlFor="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.paymentPlan}</label>
                                                <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.paymentPlan?this.state.paymentPlan.name:''} type="text" id="form80" class="form-control"  />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="2" xs="2">
                                          
                                            <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                                <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                                onClick={()=>{this.props.history.push('paymentPlanInfo',{data:this.state.paymentPlan}) }}>
                                                  remove_red_eye
                                                </span>
                                                <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                                  onClick={()=>{this.props.history.push('paymentPlanInfo',{data:this.state.paymentPlan}) }}>
                                                  {allStrings.show}
                                                </span> 
                                              </p>
                                            </MDBCol>
                                        </MDBRow>
                                        }
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                          <label className="labelBox" style={{textAlign:this.props.isRTL?'right':'left'}}>
                                            <span>{allStrings.subFeatures}</span>
                                          </label>
                                          {this.state.features.length > 0 ?
                                            this.state.features.map((val, index) =>
                                              <MDBCol md='3' style={{textAlign:'center'}}>
                                              <img src={val.img} style={{width:'50px'}} alt=""/>
                                              <p style={{textAlign:'center',width:'100%'}}>{val.name}</p>
                                              </MDBCol>
                                            )
                                            :
                                            <MDBCol md="12">
                                              <Empty description={allStrings.noData} style={{margin:'0rem'}}/> 
                                            </MDBCol>
                                          }
                                        </MDBRow>
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                          <label className="labelBox" style={{textAlign:this.props.isRTL?'right':'left'}}>
                                            <span>{allStrings.mainFeatures}</span>
                                          </label>
                                          {this.state.properties.length > 0 ?
                                            this.state.properties.map((val, index) =>
                                            val.type !=="LIST"?
                                              <MDBCol md='3' style={{textAlign:'center'}}>
                                              <img src={val.img} style={{width:'50px'}} alt=""/>
                                              <p style={{textAlign:'center',width:'100%',marginBottom:'4px'}}>{val.name}</p>
                                              <p style={{textAlign:'center',width:'100%'}}>{val.value}</p>
                                              </MDBCol>
                                              :
                                              <MDBCol md='3' style={{textAlign:'center'}}>
                                                <img src={val.img} style={{width:'50px'}} alt=""/>
                                                <p style={{textAlign:'center',width:'100%',marginBottom:'4px'}}>{val.name}</p>
                                                <p style={{textAlign:'center',width:'100%'}}>{val.value}</p>
                                              </MDBCol>
                                            )
                                            :
                                            <MDBCol md="12">
                                              <Empty description={allStrings.noData} style={{margin:'0rem'}}/> 
                                            </MDBCol>
                                          }
                                        </MDBRow>
                                        {this.state.categoryType==="PLANNED" &&
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                          <label className="labelBox" style={{textAlign:this.props.isRTL?'right':'left'}}>
                                            <span>{allStrings.lands}</span>
                                          </label>
                                          {this.state.lands.length > 0 ?
                                            this.state.lands.map((val, index) =>
                                              <MDBCol md="3" style={{marginBottom:'10px'}}>
                                                <div 
                                                onClick={()=>{this.setNewData(val.id)}} 
                                                style={{background: '#1D9598',padding: '4px',cursor: 'pointer',color: '#fff',fontWeight: '400'}}>
                                                <p style={{textAlign:'center',width:'100%',marginBottom:'4px'}}>{val.unitNumber}</p>
                                                </div>
                                              </MDBCol>
                                            )
                                            :
                                              <MDBCol md="12">
                                                <Empty description={allStrings.noData} style={{margin:'0rem'}}/> 
                                              </MDBCol>
                                          }
                                        </MDBRow>
                                        }
                                        {this.state.categoryType==="VILLAS" &&
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                          <label className="labelBox" style={{textAlign:this.props.isRTL?'right':'left'}}>
                                            <span>{allStrings.villas}</span>
                                          </label>
                                          {this.state.villas.length > 0 ?
                                            this.state.villas.map((val, index) =>
                                              <MDBCol md="3" style={{marginBottom:'10px'}}>
                                                <div 
                                                onClick={()=>{this.setNewData(val.id)}} 
                                                style={{background: '#1D9598',padding: '4px',cursor: 'pointer',color: '#fff',fontWeight: '400'}}>
                                                <p style={{textAlign:'center',width:'100%',marginBottom:'4px'}}>{val.unitNumber}</p>
                                                </div>
                                              </MDBCol>
                                            )
                                            :
                                              <MDBCol md="12">
                                                <Empty description={allStrings.noData} style={{margin:'0rem'}}/> 
                                              </MDBCol>
                                          }
                                        </MDBRow>
                                        }
                                        {this.state.categoryType==="OFF-PLAN-SALE" &&
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                          <label className="labelBox" style={{textAlign:this.props.isRTL?'right':'left'}}>
                                            <span>{allStrings.buildings}</span>
                                          </label>
                                          {this.state.buildings.length > 0 ?
                                            this.state.buildings.map((val, index) =>
                                              <MDBCol md="3" style={{marginBottom:'10px'}}>
                                                <div 
                                                onClick={()=>{this.setNewData(val.id)}} 
                                                style={{background: '#1D9598',padding: '4px',cursor: 'pointer',color: '#fff',fontWeight: '400'}}>
                                                <p style={{textAlign:'center',width:'100%',marginBottom:'4px'}}>{val.unitNumber}</p>
                                                </div>
                                              </MDBCol>
                                            )
                                            :
                                              <MDBCol md="12">
                                                <Empty description={allStrings.noData} style={{margin:'0rem'}}/> 
                                              </MDBCol>
                                          }
                                        </MDBRow>
                                        }
                                        {this.state.categoryType==="BUILDING" &&
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                          <label className="labelBox" style={{textAlign:this.props.isRTL?'right':'left'}}>
                                            <span>{allStrings.units}</span>
                                          </label>

                                          {this.state.units.length > 0 ?
                                            this.state.units.map((val, index) =>
                                            <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row',width:'100%'}}>
                                            <label htmlFor="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%',margin:'15px'}}>
                                              {this.props.isRTL?
                                              <span>{val.floor+ " "+ allStrings.floorNumber}</span>
                                              :
                                              <span>{" "+ allStrings.floorNumber+" "+val.floor }</span>
                                              }
                                              
                                            </label>

                                            {val.unit.map((i, index) =>
                                            <MDBCol md="3" style={{marginBottom:'10px'}}>
                                              <div 
                                              onClick={()=>{this.setNewData(i.id)}} 
                                              style={{background: '#1D9598',padding: '4px',cursor: 'pointer',color: '#fff',fontWeight: '400'}}>
                                              <p style={{textAlign:'center',width:'100%',marginBottom:'4px'}}>{i.unitNumber}</p>
                                              </div>
                                            </MDBCol>
                                            )}
                                            </MDBRow>
                                            ):
                                            <MDBCol md="12">
                                              <Empty description={allStrings.noData} style={{margin:'0rem'}}/> 
                                            </MDBCol>
                                          }
                                        </MDBRow>
                                        }
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="text-center">
                                          <MDBBtn
                                              onClick={()=>{this.toggle()}} 
                                              color="primary"
                                              rounded
                                              className="btn-block z-depth-1a"
                                            >
                                                <MDBIcon icon="plus" className="mr-2" />
                                                {allStrings.addSeoDesc} 
                                                      
                                          </MDBBtn>
                                          <MDBBtn
                                              onClick={()=>{this.toggle3()}} 
                                              color="primary"
                                              rounded
                                              className="btn-block z-depth-1a"
                                            >
                                                <MDBIcon icon="plus" className="mr-2" />
                                                {allStrings.addContentDesc} 
                                                      
                                          </MDBBtn>
                                          <MDBBtn
                                              onClick={()=>{this.props.history.push("views",{adveristment:this.state.id}) }}
                                              rounded
                                              className="btn-block z-depth-1a"
                                            >
                                                {allStrings.views} 
                                          </MDBBtn>
                                          {!this.state.isInRedf &&
                                          <MDBBtn
                                              onClick={()=>{this.sendToRedf()}} 
                                              color="primary"
                                              rounded
                                              className="btn-block z-depth-1a"
                                            >
                                                {allStrings.sendToRedf} 
                                                      
                                          </MDBBtn>
                                          }
                                          
                                        </div>
                                    </form>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                            </CardContent>
                        </Card>
                    </div>
                </MDBCol>
              {this.imageSection()}
              </MDBRow>
              {this.buttonsSection()}

              {this.state.actionType==="VIEW"&&this.adMarkeingTable()}
          </MDBContainer>
        </Spin>
      </div> 
    )
  }
}
const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});
const mapDispatchToProps = {
};
export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(adsInfo)
);