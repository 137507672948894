import React from 'react';
import './add news.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,TreeSelect} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw} from 'draft-js';
const { TreeNode } = TreeSelect;

class AddNews extends React.Component {
    state = {
        title_ar:'',
        description_en:'',
        title_en:'',
        description_ar:'',
        link:'',
        linkType:'OUTSIDE',
        imgs: [],
        imgsType:'data',
        imgsPreviewUrl: [],
        imgsName:[],
        editorState1: EditorState.createEmpty(),
        editorState2: EditorState.createEmpty(),
        contentState:{},
      };

      constructor(props){
        super(props)
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        window.scrollTo(0,0)
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        } 
      }
      componentDidMount(){
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        }
      }

      addnews = () => {
        const {description_en,title_ar,imgs,description_ar,title_en,link,linkType} = this.state
        let l = message.loading('Wait..', 1.5)
        if(imgs.length === 0)
          l.then(() => message.error(allStrings.completeData, 1.5) )
        if(hasValue(description_en)&&hasValue(title_en)&&hasValue(title_ar)
        &&hasValue(description_ar)&&hasValue(link)){
           
            let data = new FormData()
            data.append('description_en',description_en)
            data.append('title_ar',title_ar)
            data.append('description_ar',description_ar)
            data.append('title_en',title_en)
            data.append('link',link)
            data.append('linkType',linkType)
            
            if(imgs != null){
              for(let i=0 ; i<= imgs.length-1 ; i++){
                data.append(`imgs`,imgs[i] )
              } 
            }
            console.log(Array.from(data))
            axios.post(`${BASE_END_POINT}news`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("add news  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
     }
     
      submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.addnews()
      };
    
      changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
      };

     
      fileChangedHandler = (event) => {
        let imgs =[];
        let imgsPreviewUrl = [];
        let imgsName = []
       
    
        for(let i=0 ; i<= event.target.files.length-1 ; i++){
            imgsPreviewUrl.push(URL.createObjectURL(event.target.files[i]))
            imgsName.push(event.target.files[i].name)
        }
        imgs.push(event.target.files)
        this.setState({
            imgs: event.target.files,
            imgsType:'data',
            imgsPreviewUrl:imgsPreviewUrl,
            imgsName:imgsName
        });
        
      };
      removeFile = (url) => {
        let arr = this.state.imgsPreviewUrl;
        let arr2 =  Array.from(this.state.imgs);
        let arr3 = Array.from(this.state.imgsName)
        let index = arr.indexOf(url);
        if (index !== -1) arr.splice(index, 1);
        if (index !== -1) arr2.splice(index, 1);
        if (index !== -1) arr3.splice(index, 1);
        this.setState({
          imgsPreviewUrl: arr,
          imgs:arr2,
          imgsName:arr3
        });
        if(arr2.length === 0){
          this.setState({
            imgs:null,
            imgsName:[]
          });
        }
    
      };
      /**
    * convert text to html
    */
    onEditorStateChange1= (editorState) => {
      let contentBlock = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      console.log("onEditorStateChange",contentBlock)
      if(contentBlock) {
          this.setState({
              editorState1: editorState,
              description_en:contentBlock
          });
      }
    };
    /**
    * convert text to html
    */
    onEditorStateChange2 = (editorState) => {
      let contentBlock = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      console.log("onEditorStateChange",contentBlock)
      if(contentBlock) {
        this.setState({
            editorState2: editorState,
            description_ar:contentBlock
        });
      }
    };
  
    onContentStateChange1 =(contentState) => {
      console.log("contentState1",contentState)
    };
    onContentStateChange2 =(contentState) => {
      console.log("contentState2",contentState.blocks[0].text)
    };
    /**
    * upload Image in html
    */
    uploadImageCallBack(file) {
     console.log(file);
     return new Promise(
         (resolve, reject) => {
          let data = new FormData()
          data.append(`img`,file )
          axios.post(`${BASE_END_POINT}messages/upload`,data,{
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization':`Bearer ${this.props.currentUser.token}`,
              'Accept-Language':this.props.isRTL?'ar':'en'
          },
          })
          .then(response=>{
              console.log(response.data);
              resolve({data: {link: response.data.link}})
          })

          .catch(error=>{
              console.log(error.response)
              reject(error.response);
          })
         }
     );
 }
      
   
     
  render(){
    const { editorState1, editorState2} = this.state;
    /**
    * config the editor state
    */
    const toolbar = {
        inline: {
            inDropdown: false,
        },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
        emoji  :  {inDropdown: false},
        image: { 
            uploadEnabled: true,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            defaultSize: {
                height: '200',
                width: '200',
            },
            uploadCallback: this.uploadImageCallBack.bind(this), 
            alt: { present: true, mandatory: false } 
        },
    };
    const styles = {
        editor:{
            "border": "1px solid #F1F1F1",
            "padding": 5,
            "border-radius": 2,
            "height": "auto",
            "min-height": 200,
            "color":"#333"
        }
    }
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('FAQ')) return <Redirect to='/Home' />


  return (
    <div className="App">
        
        <MDBContainer>
            <MDBRow>
                <MDBCol xl ="12" lg = "12" md="12" sm="12">
                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#1D9598'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>featured_video</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{allStrings.addNews}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    
                                    <div className="grey-text">
                                        
                                        <MDBRow>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                                  <label htmlFor="form3" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishTitle}</label>
                                                  <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.title_en} type="text" id="form3" name="title_en" className="form-control" onChange={this.changeHandler} required validate/>
                                                  <div class="valid-feedback">{allStrings.correct}</div>
                                                  <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                              <div className="md-form">
                                                  <label htmlFor="form32" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicTitle}</label>
                                                  <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.title_ar} type="text" id="form32" name="title_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                                  <div class="valid-feedback">{allStrings.correct}</div>
                                                  <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                            
                                        </MDBRow>

                                        
                                        <MDBRow style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}  >
                                            <MDBCol md="6">
                                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.linkType}</label>
                                                <TreeSelect virtual={false}
                                                    showSearch={false} 
                                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                    treeCheckable = {false}         
                                                    treeNodeFilterProp="title"  
                                                    value={this.state.linkType}                    
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder={allStrings.linkType}
                                                    onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({linkType:value});
                                                    }} 
                                                
                                                >
                                                    <TreeNode value="OUTSIDE" title={allStrings.outside} key="OUTSIDE" />
                                                    <TreeNode value="INSIDE" title={allStrings.inside} key="INSIDE" />
                                                    
                                                </TreeSelect>
                                            </MDBCol>
                                            <MDBCol md="6">
                                              <div className="md-form">
                                                <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.link}</label>
                                                <input style={{direction:this.props.isRTL?'rtl':'ltr'}} type="text" id="form65" name="link" className="form-control" onChange={this.changeHandler} validate required/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                        <MDBCol md="12">
                                        <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishDescription}</label>
                                          <Editor
                                            editorState={editorState1}
                                            editorClassName={styles.editor}
                                            toolbarClassName="toolbar-class"
                                            wrapperStyle={{
                                                minHeight: 100,
                                            }}
                                            editorStyle={{
                                                minHeight: 100,
                                                border: "1px solid #F1F1F1",
                                                padding: 5,
                                                borderRadius: 2,
                                                height: "auto",
                                            }}
                                            toolbar={toolbar}
                                            onContentStateChange={this.onContentStateChange1}
                                            onEditorStateChange={this.onEditorStateChange1.bind(this)}
                                        />
                                       </MDBCol>
                                       </MDBRow>
                                       <br></br>
                                        <MDBRow>
                                        <MDBCol md="12">
                                        <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicDescription}</label>

                                        <Editor
                                            editorState={editorState2}
                                            ref='textEditor'
                                            editorClassName={styles.editor}
                                            toolbarClassName="toolbar-class"
                                            wrapperStyle={{
                                                minHeight: 100,
                                            }}
                                            editorStyle={{
                                                minHeight: 100,
                                                border: "1px solid #F1F1F1",
                                                padding: 5,
                                                borderRadius: 2,
                                                height: "auto",
                                            }}
                                            toolbar={toolbar}
                                            onContentStateChange={this.onContentStateChange2}
                                            onEditorStateChange={this.onEditorStateChange2.bind(this)}
                                        />
                                        </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12">
                                            <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.img}</label>

                                                <div className="fileUpload2"> 
                                                    <input multiple key={this.state.imgs} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                    <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                </div>
                                                <div className="preview">
                                                {this.state.imgsPreviewUrl.map((val,i) => (
                                                  <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine' ,marginBottom:'10px'}}>
                                                      <img alt="" src={this.state.imgsType==="data"?URL.createObjectURL(this.state.imgs[i]):this.state.imgs[i]} className="previewImg2"/>
                                                      <span className="imgName">{this.state.imgsName[i]}</span>
                                                      <MDBIcon icon="trash"  className="mr-2 removeImg5"
                                                      onClick={() => this.removeFile(val)}  ></MDBIcon>
                                                  </div>
                                                ))}
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn
                                          type="submit" style={{background:'#1D9598'}}
                                          rounded
                                          className="btn-block z-depth-1a"
                                        >
                                            <MDBIcon icon="plus" className="mr-2" />
                                            {allStrings.addNews}        
                                        </MDBBtn>
                                    </div>
                                </form>
                                </MDBCol>
                                
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
            
            
        </MDBContainer>
      
    </div> 
  )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(AddNews)
  );